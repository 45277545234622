var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"main",staticClass:"main"},[_c('div',{staticClass:"pcBox"},[_c('div',{staticClass:"home pageItem",style:({
        backgroundImage:
          'url(' + require('@/views/aimi/assets/img_guanwang_bg.jpg') + ')',
      }),attrs:{"id":"download"}},[_c('Header',{attrs:{"showFixedNav":_vm.showFixedNav,"currentTab":_vm.currentTab},on:{"update:currentTab":function($event){_vm.currentTab=$event},"update:current-tab":function($event){_vm.currentTab=$event}}}),_c('div',{staticClass:"downLoad"},[_c('div',{staticClass:"downLoadBtn"},[_c('el-image',{attrs:{"src":require('@/images/home/btn_android.png')}}),_c('div',{staticClass:"downLoadQrc"},[_c('el-image',{attrs:{"src":require('@/views/aimi/assets/download.png')}})],1)],1)]),_c('div',{ref:"phoneBox",staticClass:"phoneBox",style:({
          backgroundImage: `url(${require('@/images/home/img_pq_zhutu.png')}`,
        })},[_c('img',{attrs:{"src":require("@/images/home/img_pqzt_dalinzi.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_fujin.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_zhenren.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_paipai.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_youhua.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_mimi.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_putong.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_qingbaoju.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_moqiyibai.png")}})])],1),_c('div',{ref:"contact",staticClass:"Map pageItem",style:({
        backgroundImage:
          'url(' + require('@/images/home/img_pqbg_lianxi.jpg') + ')',
      }),attrs:{"id":"contact"}},[_c('div',{staticClass:"mapBox"},[_c('div',{staticClass:"companyInfo"},[_c('el-image',{staticClass:"companyInfoTitle",attrs:{"src":require('@/images/home/img_lianxi_biaoti.png')}}),_vm._m(0)],1)])])]),_c('div',{staticClass:"mobileBox"},[_c('MHeader'),_c('el-image',{attrs:{"src":require('@/views/aimi/assets/m-first-page.jpg')}})],1),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"companyCardBox"},[_c('div',{staticClass:"companyCard"},[_c('div',{staticClass:"companyCardTitle"},[_vm._v("广州友迅网络科技有限公司")]),_c('div',{staticClass:"companyCardHeng"}),_c('div',{staticClass:"companyCardRow"},[_vm._v(" 联系邮箱 "),_c('div',{staticClass:"mgl16"},[_vm._v("pique@myouxun.com")])]),_c('div',{staticClass:"companyCardRow"},[_vm._v(" 联系方式 "),_c('div',{staticClass:"mgl16"},[_vm._v("020-37324930")])]),_c('div',{staticClass:"companyCardRow"},[_vm._v(" 公司地址 "),_c('div',{staticClass:"mgl16"},[_vm._v("广州市白云区尖彭路371号A栋210室")])])])])
}]

export { render, staticRenderFns }