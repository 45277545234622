<template>
	<div>
		
		<div class="title">第三方信息共享清单</div>
		<div class="content">
			当你使用第三方提供的服务时，我们会在获得或确保第三方获得你的授权同意后，以及其他符合法律法规规定的情形下共享对应信息。你可以通过本清单中列举的相关信息了解第三方会如何处理你的个人信息。我们也会对第三方获取个人信息的行为进行严格约束，以保护你的个人信息安全。<br>
			为保障皮雀稳定运行或实现相关功能，我们还可能会接入由第三方提供的软件开发包 (SDK)实现前述目的。我们对接入的相关第三方SDK同时在以下清单中列明。你可以通过目录中提供的链接或者路径查看第三方的数据使用和保护规则。请注意，第三方SDK可能由于版本升级、策略调整等原因导致其个人信息处理类型发生变化，请以其公示的官方说明为准。<br>
			<span class="fw600">腾讯云IM</span><br>
			第三方主体：腾讯<br>
			共享的信息：用户手机电话号码<br>
			使用场景：聊天通讯服务<br>
			使用目的：满足用户使用畅聊广场功能<br>
			共享方式：提供设备聊天通讯、登录登出服务，需要查看账号信息、接收验证码权限。<br>
			第三方个人信息处理规则：http://pique.nnwmkj.com/privacyPolicy<br>
			<span class="fw600">腾讯优量汇</span><br>
			第三方主体：腾讯<br>
			共享的信息：手机设备识别码<br>
			使用场景：小游戏展示广告，小游戏看广告<br>
			使用目的：满足用户流畅玩游戏<br>
			共享方式：提供小游戏展示广告，需要收集手机设备识别码，包括Android ID (仅安卓) 、IDFA、SIM卡IMSI信息<br>
			第三方个人信息处理规则：http://pique.nnwmkj.com/privacyPolicy<br>


		</div>
	</div>
</template>
<script>

export default {
	components: {
	},
	data() {
		return {
			pageindex: '1',
		}
	},
	methods: {
		changPage(e) {
			// console.log(e);
			this.pageindex = e
		}
	},
};

</script>
<style scoped>
.title{
	text-align: center;
	font-size: 28px;
	font-weight: 600;
}
.content{
	line-height: 38px;
}
.fw600{
	font-weight: 600;

}
.fz18{
	font-size: 20px;
}
.fcred{
	color: red;
}
</style>
  
  