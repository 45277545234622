var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"main",staticClass:"main"},[_c('div',{staticClass:"home pageItem",style:({
        backgroundImage:
          'url(' + require('@/images/home/img_guanwang_bg.jpg') + ')',
      }),attrs:{"id":"download"}},[_c('Header',{attrs:{"showFixedNav":_vm.showFixedNav,"currentTab":_vm.currentTab},on:{"update:currentTab":function($event){_vm.currentTab=$event},"update:current-tab":function($event){_vm.currentTab=$event}}}),_c('div',{staticClass:"downLoad"},[_c('div',{staticClass:"downLoadBtn",on:{"mouseenter":function($event){return _vm.mouseenter(1)},"mouseleave":function($event){return _vm.mouseleave(1)}}},[_c('el-image',{attrs:{"src":require('@/images/home/btn_ios.png')}})],1),_c('div',{staticClass:"downLoadBtn",on:{"mouseenter":function($event){return _vm.mouseenter(2)},"mouseleave":function($event){return _vm.mouseleave(2)}}},[_c('el-image',{attrs:{"src":require('@/images/home/btn_android.png')}})],1)]),_c('div',{staticClass:"downLoadQrc",class:_vm.showQrcodeIos ? 'downLoadQrcS' : ''},[_c('el-image',{attrs:{"src":require('@/images/home/download-qrcode2.png')}})],1),_c('div',{staticClass:"downLoadQrc downLoadQrcR",class:_vm.showQrcodeAd ? 'downLoadQrcS' : ''},[_c('el-image',{attrs:{"src":require('@/images/home/download-qrcode2.png')}})],1),_c('div',{ref:"phoneBox",staticClass:"phoneBox",style:({
          backgroundImage: `url(${require('@/images/home/img_pq_zhutu.png')}`,
        })},[_c('img',{attrs:{"src":require("@/images/home/img_pqzt_dalinzi.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_fujin.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_zhenren.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_paipai.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_youhua.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_mimi.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_putong.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_qingbaoju.png")}}),_c('img',{attrs:{"src":require("@/images/home/img_pqzt_moqiyibai.png")}})]),_c('el-image',{staticClass:"tipsImg",attrs:{"src":require('@/images/home/img_pq_jieshao.png')}})],1),_c('div',{ref:"introduceBox",staticClass:"introduceBox",attrs:{"id":"introduce"}},[_c('div',{staticClass:"introduceNav"},[_c('el-tabs',{on:{"tab-click":_vm.introduceNavClick},model:{value:(_vm.introduceNav),callback:function ($$v) {_vm.introduceNav=$$v},expression:"introduceNav"}},[_c('el-tab-pane',{attrs:{"label":"附近/推荐","name":"1"}}),_c('el-tab-pane',{attrs:{"label":"真人认证","name":"2"}}),_c('el-tab-pane',{attrs:{"label":"拍拍交友","name":"3"}}),_c('el-tab-pane',{attrs:{"label":"大林子","name":"4"}}),_c('el-tab-pane',{attrs:{"label":"心动右滑","name":"5"}}),_c('el-tab-pane',{attrs:{"label":"噗通信号","name":"6"}}),_c('el-tab-pane',{attrs:{"label":"雀友情报局","name":"7"}}),_c('el-tab-pane',{attrs:{"label":"默契100%","name":"8"}}),_c('el-tab-pane',{attrs:{"label":"召唤上线","name":"9"}})],1)],1),_c('div',[_c('div',{ref:"introduceItem1",staticClass:"introduceItem introduceItem1",style:({
            backgroundImage: `url(${require('@/images/home/img_gongneng_1.jpg')}`,
          })},[_c('div',{staticClass:"introduceItemInner"},[_c('div',{staticClass:"introduceItemText"},[_c('el-image',{staticClass:"introduceItemIcon",attrs:{"src":require('@/images/home/ic_gn_fujin.png')}}),_c('div',{staticClass:"introduceItemTitle"},[_c('div',{staticClass:"z2"},[_vm._v("附近推荐")]),_c('el-image',{staticClass:"introduceItemBgimg",attrs:{"src":require('@/images/home/img_xiaobiaoti.png')}})],1),_vm._m(0)],1)])]),_c('div',{ref:"introduceItem2",staticClass:"introduceItem introduceItem2",style:({
            backgroundImage: `url(${require('@/images/home/img_gongneng_2.jpg')}`,
          })},[_c('div',{staticClass:"introduceItemInner"},[_c('div',{staticClass:"introduceItemText"},[_c('el-image',{staticClass:"introduceItemIcon",attrs:{"src":require('@/images/home/ic_gn_zhenrenrenzheng.png')}}),_c('div',{staticClass:"introduceItemTitle"},[_c('div',{staticClass:"z2"},[_vm._v("真人认证")]),_c('el-image',{staticClass:"introduceItemBgimg",attrs:{"src":require('@/images/home/img_xiaobiaoti.png')}})],1),_vm._m(1)],1)])]),_c('div',{ref:"introduceItem3",staticClass:"introduceItem introduceItem3",style:({
            backgroundImage: `url(${require('@/images/home/img_gongneng_3.jpg')}`,
          })},[_c('div',{staticClass:"introduceItemInner"},[_c('div',{staticClass:"introduceItemText"},[_c('el-image',{staticClass:"introduceItemIcon",attrs:{"src":require('@/images/home/ic_gn_paipaijiaoyou.png')}}),_c('div',{staticClass:"introduceItemTitle"},[_c('div',{staticClass:"z2"},[_vm._v("拍拍交友")]),_c('el-image',{staticClass:"introduceItemBgimg",attrs:{"src":require('@/images/home/img_xiaobiaoti.png')}})],1),_vm._m(2)],1)])]),_c('div',{ref:"introduceItem4",staticClass:"introduceItem introduceItem4",style:({
            backgroundImage: `url(${require('@/images/home/img_gongneng_4.jpg')}`,
          })},[_c('div',{staticClass:"introduceItemInner"},[_c('div',{staticClass:"introduceItemText"},[_c('el-image',{staticClass:"introduceItemIcon",attrs:{"src":require('@/images/home/ic_gn_dalinzi.png')}}),_c('div',{staticClass:"introduceItemTitle"},[_c('div',{staticClass:"z2"},[_vm._v("大林子")]),_c('el-image',{staticClass:"introduceItemBgimg",attrs:{"src":require('@/images/home/img_xiaobiaoti.png')}})],1),_vm._m(3)],1)])]),_c('div',{ref:"introduceItem5",staticClass:"introduceItem introduceItem5",style:({
            backgroundImage: `url(${require('@/images/home/img_gongneng_5.jpg')}`,
          })},[_c('div',{staticClass:"introduceItemInner"},[_c('div',{staticClass:"introduceItemText"},[_c('el-image',{staticClass:"introduceItemIcon",attrs:{"src":require('@/images/home/ic_gn_xindongyouhua.png')}}),_c('div',{staticClass:"introduceItemTitle"},[_c('div',{staticClass:"z2"},[_vm._v("心动右滑")]),_c('el-image',{staticClass:"introduceItemBgimg",attrs:{"src":require('@/images/home/img_xiaobiaoti.png')}})],1),_vm._m(4)],1)])]),_c('div',{ref:"introduceItem6",staticClass:"introduceItem introduceItem6",style:({
            backgroundImage: `url(${require('@/images/home/img_gongneng_6.jpg')}`,
          })},[_c('div',{staticClass:"introduceItemInner"},[_c('div',{staticClass:"introduceItemText"},[_c('el-image',{staticClass:"introduceItemIcon",attrs:{"src":require('@/images/home/ic_gn_putongxinhao.png')}}),_c('div',{staticClass:"introduceItemTitle"},[_c('div',{staticClass:"z2"},[_vm._v("噗通信号")]),_c('el-image',{staticClass:"introduceItemBgimg",attrs:{"src":require('@/images/home/img_xiaobiaoti.png')}})],1),_vm._m(5)],1)])]),_c('div',{ref:"introduceItem7",staticClass:"introduceItem introduceItem7",style:({
            backgroundImage: `url(${require('@/images/home/img_gongneng_7.jpg')}`,
          })},[_c('div',{staticClass:"introduceItemInner"},[_c('div',{staticClass:"introduceItemText"},[_c('el-image',{staticClass:"introduceItemIcon",attrs:{"src":require('@/images/home/ic_gn_qingbaoju.png')}}),_c('div',{staticClass:"introduceItemTitle"},[_c('div',{staticClass:"z2"},[_vm._v("雀友情报局")]),_c('el-image',{staticClass:"introduceItemBgimg",attrs:{"src":require('@/images/home/img_xiaobiaoti.png')}})],1),_vm._m(6)],1)])]),_c('div',{ref:"introduceItem8",staticClass:"introduceItem introduceItem8",style:({
            backgroundImage: `url(${require('@/images/home/img_gongneng_8.jpg')}`,
          })},[_c('div',{staticClass:"introduceItemInner"},[_c('div',{staticClass:"introduceItemText"},[_c('el-image',{staticClass:"introduceItemIcon",attrs:{"src":require('@/images/home/ic_gn_moqiyibai.png')}}),_c('div',{staticClass:"introduceItemTitle"},[_c('div',{staticClass:"z2"},[_vm._v("默契100%")]),_c('el-image',{staticClass:"introduceItemBgimg",attrs:{"src":require('@/images/home/img_xiaobiaoti.png')}})],1),_vm._m(7)],1)])]),_c('div',{ref:"introduceItem9",staticClass:"introduceItem introduceItem9",style:({
            backgroundImage: `url(${require('@/images/home/img_gongneng_9.jpg')}`,
          })},[_c('div',{staticClass:"introduceItemInner"},[_c('div',{staticClass:"introduceItemText"},[_c('el-image',{staticClass:"introduceItemIcon",attrs:{"src":require('@/images/home/ic_gn_zhaohuanshangxian.png')}}),_c('div',{staticClass:"introduceItemTitle"},[_c('div',{staticClass:"z2"},[_vm._v("召唤上线")]),_c('el-image',{staticClass:"introduceItemBgimg",attrs:{"src":require('@/images/home/img_xiaobiaoti.png')}})],1),_vm._m(8)],1)])])])]),_c('div',{ref:"Map",staticClass:"aboutWMBox",attrs:{"id":"about"}},[_c('el-image',{staticClass:"aboutWMImg",attrs:{"src":require('@/images/home/img_weimijieshao.jpg')}})],1),_c('div',{staticClass:"Map pageItem",style:({
        backgroundImage:
          'url(' + require('@/images/home/img_pqbg_lianxi.jpg') + ')',
      })},[_c('div',{staticClass:"mapBox"},[_c('div',{staticClass:"companyInfo"},[_c('el-image',{staticClass:"companyInfoTitle",attrs:{"src":require('@/images/home/img_lianxi_biaoti.png')}}),_c('div',{staticClass:"companyCardBox"},[_vm._m(9),_c('div',{staticClass:"companyCard companyCardsmall"},[_c('el-image',{staticClass:"companyCardQrcode",attrs:{"src":require('@/images/home/picQrcode.png')}}),_vm._m(10)],1)])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduceItemContent"},[_vm._v(" 让缘分触手可及！"),_c('br'),_vm._v("有趣的人近在咫尺 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduceItemContent"},[_vm._v(" 真人+实名认证让你安心交友！"),_c('br'),_vm._v("脱单第一步从真人多的皮雀开始 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduceItemContent"},[_vm._v(" 用一次就上瘾的识别神器！"),_c('br'),_vm._v("只需3秒即可找到心仪的TA ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduceItemContent"},[_vm._v(" 你我的故事从点赞开始！"),_c('br'),_vm._v("自由分享生活的每一个瞬间 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduceItemContent"},[_vm._v(" 超多帅哥美女大饱眼福！"),_c('br'),_vm._v("左滑or右滑，社交主动权在你手中 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduceItemContent"},[_vm._v(" 缘分来临就会响起心动的信号！"),_c('br'),_vm._v("双击头像快速打开附近有缘人的窗口 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduceItemContent"},[_vm._v(" 找人找物一键发布！"),_c('br'),_vm._v("召集雀友的力量"),_c('br'),_vm._v("助你实现一切找寻的愿望 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduceItemContent"},[_vm._v(" 让志趣相投的人更好相遇！"),_c('br'),_vm._v("默契考验让你跟三观不合的人说拜拜~ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduceItemContent"},[_vm._v(" 想念TA却不在线？"),_c('br'),_vm._v("召唤好友上限，重启畅聊时刻 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"companyCard"},[_c('div',{staticClass:"companyCardTitle"},[_vm._v("南宁微觅科技")]),_c('div',{staticClass:"companyCardHeng"}),_c('div',{staticClass:"companyCardRow"},[_vm._v(" 联系邮箱 "),_c('div',{staticClass:"mgl16"},[_vm._v("pique@myouxun.com")])]),_c('div',{staticClass:"companyCardRow"},[_vm._v(" 联系方式 "),_c('div',{staticClass:"mgl16"},[_vm._v("020-36139859")])]),_c('div',{staticClass:"companyCardRow"},[_vm._v(" 公司地址 "),_c('div',{staticClass:"mgl16"},[_vm._v("广西壮族自治区南宁江南万达广场C16号楼")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"companyCardQrcodeText"},[_vm._v(" 更多有趣内容"),_c('br'),_vm._v("请关注微信公众号"),_c('br'),_vm._v("「皮雀官方号」 ")])
}]

export { render, staticRenderFns }