<template>
  <div
    class="container"
    :style="{
      backgroundImage: `url(${require('@/images/img_bg_zixun.jpg')}`,
    }"
  >
    <Header :showFixedNav="true" currentTab="4" />

    <div class="search-bar">
      <div class="search-bar-inner">
        <div class="search-bar-title">皮雀动态信息</div>
        <div class="search-bar-input-control">
          <div class="search-bar-input-wrapper">
            <input
              class="search-bar-input"
              v-model.trim="keyword"
              placeholder="请输入关键词进行搜索"
              @keydown.enter.prevent.stop="handleSearch"
            />
            <div
              v-if="keyword"
              class="search-bar-input-close"
              @click="handleClear"
            >
              <img src="@/images/ic_denglu_qingchushuru@2x.png" />
            </div>
          </div>
          <div class="search-bar-submit" @click="handleSearch">
            <i v-if="loadMoreStatus === 'loading'" class="el-icon-loading"></i>
            <span v-else>搜索</span>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <Empty
        v-if="isEmpty"
        style="padding-top: 87px"
        title="暂无结果，换个关键词试试看~"
      ></Empty>

      <div v-else>
        <ul class="news-list">
          <li
            v-for="item in list"
            :key="item.id"
            class="news-list-item"
            @click="navigateToDetail(item)"
          >
            <div class="news-list-item-header">
              <div class="news-list-item-date">
                <div class="news-list-item-date-month">
                  {{ moment(item.created_at).format("MM-DD") }}
                </div>
                <div class="news-list-item-date-year">
                  / {{ moment(item.created_at).format("YYYY") }}
                </div>
              </div>
            </div>
            <div class="news-list-item-body">
              <div class="news-list-item-title">
                {{ item.title }}
              </div>
              <div class="news-list-item-description">
                {{ item.short_desc }}
              </div>
              <div class="news-list-item-read">
                <div class="news-list-item-read-title">阅读全文</div>
                <div class="news-list-item-date-m">{{ item.created_at }}</div>
                <img
                  class="news-list-item-read-arrow"
                  src="@/images/ic_zixun_jiantou@2x.png"
                />
              </div>
            </div>
            <div v-if="item.cover" class="news-list-item-footer">
              <img class="news-list-item-thumb" :src="item.cover" />
            </div>
          </li>
        </ul>

        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            :current-page.sync="currentPage"
            @current-change="handleCurrentPageChange"
            hide-on-single-page
          >
          </el-pagination>
        </div>

        <div class="load-more" @click="handleLoadMoreClick">
          {{ loadMoreText[loadMoreStatus] }}
        </div>
      </div>
    </div>

    <ScrollToTop />

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Empty from "@/components/Empty.vue";
import ScrollToTop from "@/components/ScrollToTop.vue";
import moment from "moment";

import { getNewsList } from "@/api/cms";

export default {
  components: {
    Header,
    Footer,
    Empty,
    ScrollToTop,
  },
  data() {
    return {
      keyword: "",

      list: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loadMoreStatus: "more",
      loadMoreText: {
        more: "上拉显示更多",
        loading: "正在加载...",
        noMore: "- 已经到底了 -",
        error: "加载错误，点击重新加载",
      },
    };
  },
  computed: {
    isEmpty() {
      return (
        this.loadMoreStatus !== "loading" &&
        this.loadMoreStatus !== "error" &&
        this.list.length === 0
      );
    },
  },
  watch: {
    $route: {
      handler() {
        this.keyword = this.$route.query.keyword;
        this.currentPage = parseInt(this.$route.query.page) || 1;
        this.getList(true);
      },
      immediate: true,
    },
  },
  created() {
    this.getList();
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandler);
  },
  unmounted() {
    window.removeEventListener("scroll", this.scrollHandler);
  },
  methods: {
    moment,
    navigate() {
      this.$router.push({
        path: "/news",
        query: {
          keyword: this.keyword,
          page: this.currentPage,
        },
      });
    },
    handleClear() {
      this.keyword = "";
      this.handleSearch();
    },
    handleSearch() {
      window.scrollTo(0, 0);
      this.currentPage = 1;
      this.navigate();
    },
    handleCurrentPageChange() {
      window.scrollTo(0, 0);
      this.navigate();
    },
    scrollHandler() {
      if (window.innerWidth > 768) {
        return;
      }
      if (
        window.scrollY >=
        document.documentElement.scrollHeight - window.innerHeight - 50
      ) {
        if (this.loadMoreStatus !== "noMore") {
          this.getList();
        }
      }
    },
    handleLoadMoreClick() {
      if (this.loadMoreStatus !== "error") {
        return;
      }
      this.getList();
    },

    getList(flush) {
      if (this.loadMoreStatus === "loading") {
        return;
      }
      this.loadMoreStatus = "loading";

      getNewsList({
        keyword: this.keyword,
        page: this.currentPage,
        pagesize: this.pageSize,
      })
        .then((res) => {
          this.total = res.data.pagination.total;

          if (window.innerWidth < 768) {
            if (flush) {
              this.list = res.data.list;
            } else {
              this.list = [...this.list, ...res.data.list];
            }

            if (this.currentPage >= res.data.pagination.total_page) {
              this.loadMoreStatus = "noMore";
            } else {
              this.currentPage += 1;
              this.loadMoreStatus = "more";
            }
          } else {
            this.list = res.data.list;
            if (this.currentPage >= res.data.pagination.total_page) {
              this.loadMoreStatus = "noMore";
            } else {
              this.loadMoreStatus = "more";
            }
          }
        })
        .catch(() => {
          this.loadMoreStatus = "error";
        });
    },
    navigateToDetail(item) {
      this.$router.push(`/news/${item.id}`);
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
  background: #f2fafa;
  background-size: 0;
}
.search-bar {
  position: sticky;
  top: 120px;
  z-index: 10;
  background: var(--primary-color);
}
.search-bar-inner {
  display: flex;
  align-items: center;
  height: 64px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
.search-bar-title {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  line-height: 25px;
}
.search-bar-input-control {
  display: flex;
  margin-left: auto;
  border-radius: 39px;
  border: 1px solid #ffffff;
  width: 384px;
}
.search-bar-input-wrapper {
  flex: 1;
  position: relative;
}
.search-bar-input {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding-left: 42px;
  color: #fff;
  font-size: 14px;
  background: url("@/images/ic_search_grey@2x.png") 16px center / 18px 18px
    no-repeat;
  border: none;
  outline: none;
}
.search-bar-input::placeholder {
  color: #fff;
}
.search-bar-input-close {
  position: absolute;
  right: 12px;
  top: 11px;
  cursor: pointer;
}
.search-bar-input-close img {
  display: block;
  width: 18px;
  height: 18px;
}
.search-bar-submit {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 16px;
  cursor: pointer;
  width: 64px;
  font-size: 14px;
}
.search-bar-submit::before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 14px;
  width: 1px;
  transform: translateY(-50%);
  background-color: #fff;
  content: "";
}
.content {
  box-sizing: border-box;
  padding-bottom: 48px;
  min-height: calc(100vh - 120px - 64px - 80px);
}

.news-list {
  position: relative;
  max-width: 1200px;
  margin: 16px auto 0;
  padding: 0 15px;
}
.news-list-item {
  position: relative;
  display: flex;
  padding: 13px 12px 11px 24px;
  border-radius: 16px;
  background: #ffffff;
  cursor: pointer;
}
.news-list-item ~ .news-list-item {
  margin-top: 16px;
}
.news-list-item-date {
  margin-top: 12px;
  text-align: left;
}
.news-list-item-date-month {
  font-weight: 600;
  font-size: 18px;
  color: #343940;
  line-height: 25px;
}
.news-list-item-date-year {
  font-weight: 400;
  font-size: 12px;
  color: #9fa7a7;
  line-height: 17px;
}
.news-list-item-body {
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  margin-left: 48px;
}
.news-list-item-title {
  margin-top: 12px;
  font-weight: 400;
  font-size: 18px;
  color: #1f2626;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news-list-item-description {
  margin-top: 12px;
  margin-bottom: auto;
  font-weight: 400;
  font-size: 14px;
  color: #9fa7a7;
  line-height: 24px;
}
.news-list-item-read {
  display: flex;
  margin-top: 24px;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 12px;
  border-top: 1px solid #f5f9f9;
}
.news-list-item-read-title {
  font-size: 12px;
  color: #9fa7a7;
  line-height: 17px;
}
.news-list-item-read-arrow {
  display: none;
  margin-left: auto;
  width: 12px;
  height: 12px;
}
.news-list-item-date-m {
  display: none;
}
.news-list-item:hover .news-list-item-title {
  color: var(--primary-color);
}
.news-list-item:hover .news-list-item-read {
  border-color: var(--primary-color);
}
.news-list-item:hover .news-list-item-read-title {
  color: var(--primary-color);
}
.news-list-item:hover .news-list-item-read-arrow {
  display: block;
}
.news-list-item-footer {
  margin-left: 50px;
}
.news-list-item-thumb {
  display: flex;
  width: 400px;
  height: 225px;
  border-radius: 12px;
  object-fit: cover;
}
.pagination {
  margin-top: 48px;
  display: flex;
  justify-content: center;
}
.pagination ::v-deep .el-pagination.is-background .btn-prev,
.pagination ::v-deep .el-pagination.is-background .btn-next,
.pagination ::v-deep .el-pagination.is-background .el-pager li {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #eef2f2;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #464b4b;
}
.pagination
  ::v-deep
  .el-pagination.is-background
  .el-pager
  li:not(.disabled):hover {
  color: var(--primary-color);
}
.pagination
  ::v-deep
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background: var(--primary-color);
  color: #fff;
}

.load-more {
  display: none;
  margin-top: 35px;
  font-size: 12px;
  color: #606464;
  line-height: 17px;
  text-align: center;
}

@media (max-width: 768px) {
  .container {
    background-color: #f5f5f9;
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .search-bar {
    top: 50px;
    background-color: transparent;
  }
  .search-bar-title {
    display: none;
  }
  .search-bar-input-control {
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 2px 11px 0px rgba(0, 111, 102, 0.06);
  }
  .search-bar-inner {
    height: auto;
    padding: 12px 0;
  }
  .search-bar-input {
    color: #1f2626;
    background: url("@/images/ic_search_grey@2x-black.png") 16px center / 18px
      18px no-repeat;
  }
  .search-bar-input::placeholder {
    color: #c2c8c8;
  }
  .search-bar-submit {
    color: #1f2626;
  }
  .search-bar-submit::before {
    background-color: #dadede;
  }
  .content {
    min-height: calc(100vh - 50px - 66px);
  }
  .news-list-item {
    flex-direction: column-reverse;
    padding: 0;
    border-radius: 16px;
  }
  .news-list-item-header {
    display: none;
  }
  .news-list-item-body {
    margin-left: 0;
    padding: 10px 12px 16px;
  }
  .news-list-item-title {
    font-size: 16px;
    margin-top: 0;
  }
  .news-list-item-footer {
    margin-left: 0;
  }
  .news-list-item-description {
    margin-top: 6px;
    font-size: 13px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .news-list-item-thumb {
    width: 100%;
    height: 193px;
    border-radius: 12px 12px 0px 0px;
  }
  .news-list-item-read {
    padding: 0;
    margin-top: 12px;
    border: none;
  }
  .news-list-item-read-title {
    color: var(--primary-color);
  }
  .news-list-item-read-arrow {
    display: none !important;
  }
  .news-list-item-date-m {
    display: block;
    margin-left: auto;
    font-size: 12px;
    color: #9fa7a7;
    line-height: 17px;
  }
  .pagination {
    display: none;
  }
  .load-more {
    display: block;
  }
}
</style>
