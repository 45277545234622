// import Vue from 'vue'
// import VueRouter from 'vue-router'

// Vue.use(VueRouter)


// const routes = [
  
//   // {
//   //   path: '/',
//   //   redirect:'/home'
//   // },
//   {
//     path: '/',
//     component: () => import('@/layout'),
//   },
//   {
//     path:'/home',
//     name: 'Home',
//     component: () => import('@/layout'),
    
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   }
// ]

// const router = new VueRouter({
//   mode: 'history',
//   routes
// })

// export default router

import Vue from "vue"
import Router from "vue-router"
 
Vue.use(Router)

import Home from "@/views/Home"
import NewsList from "@/views/news/index.vue"
import NewsDetail from "@/views/news/detail.vue"
import StaffService from "@/views/StaffService.vue"

import HelloWorld from "@/components/HelloWorld"
import PrivacyPolicy from "@/views/PrivacyPolicy"
import InformationChecklist from "@/views/InformationChecklist"
import Test from "@/views/AboutView"


import AiMi from "@/views/aimi/index.vue"


 
//每次用户请求都需要创建一个新的router实例
//创建createRouter工厂函数
export default function createRouter() {
    //创建router实例
    return new Router({
        mode: "history",
        routes: [
            {
              path: '/',
              component: Home,
            },
            {
              path: '/news',
              component: NewsList,
            },
            {
              path: '/news/:id',
              component: NewsDetail,
            },
            {
              path: '/staff-service',
              component: StaffService,
            },
            {
              path: '/privacyPolicy',
              component: PrivacyPolicy,
              name:'privacyPolicy'
            },
            {
              path: '/InformationChecklist',
              component: InformationChecklist,
              name:'InformationChecklist'
            },
            {
              path: '/test',
              name: 'test',
              // route level code-splitting
              // this generates a separate chunk (about.[hash].js) for this route
              // which is lazy-loaded when the route is visited.
              component: Test,

            },
            // {
            //   path: '/',
            //   component: () => import('@/layout'),
            // },
            // {
            //   path:'/home',
            //   name: 'Home',
            //   component: () => import('@/layout'),
              
            // },

            {
              path: '/aimi',
              component: AiMi,
              name:'AiMi'
            },
        ],
        scrollBehavior (to) {
          if (to.path !== '/' || window.innerWidth <= 750) {
            return { x: 0, y: 0 }
          }
        }
    })
}


