import Vue from "vue"
import App from "./App.vue"
import createRouter from "./router"
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false

Vue.use(ElementUI); 


//创建createApp工厂函数
export default function createApp() {
    const router = createRouter()
    //创建vue实例
    const app = new Vue({
        store,
        router,
        render: h => h(App),
    })
    return { app, router, store}
}