<template>
  <div class="footer">
    <div class="footer-row">
      <div>南宁微觅科技有限公司版权所有2023-2028</div>
      <div @click="linkTo" style="cursor: pointer; text-decoration: underline">
        桂ICP备2023004288号
      </div>
    </div>

    <div class="footer-row">
      <div>开发者：南宁微觅科技有限公司 版本:1.0.0</div>

      <div style="cursor: pointer; margin-left: 10px">
        <a
          href="https://h5.nnwmkj.com/rule?key=website_privacy_policy"
          target="_blank"
        >
          查看软件权限《查看隐私协议》
        </a>
        <a
          href="https://h5.nnwmkj.com/rule?key=website_user_agreement"
          target="_blank"
        >
          《用户协议》
        </a>
      </div>
    </div>

    <div class="footer-row">
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010502000910"
      >
        <img :src="require('@/images/home/beian.png')" />
        <span style="margin-left: 5px">桂公网安备 45010502000910号</span>
      </a>
    </div>

    <div class="m-download">
      <div class="dbzw"></div>
      <!-- 固定底部栏 -->
      <div class="mobileFixed">
        <div class="mobileFixedRow1" id="downloadButton">
          <div class="mobileDownBtn" @click="gotoDownLoadPage(1)">
            <el-image
              class="mobileDownBtnimg"
              :src="require(`@/images/phone/ic_ios.png`)"
            />
            iPhone版下载
          </div>
          <div
            class="mobileDownBtn mobileDownBtn_g"
            @click="gotoDownLoadPage(2)"
          >
            <el-image
              class="mobileDownBtnimg"
              :src="require(`@/images/phone/ic_android.png`)"
            />
            Android版下载
          </div>
        </div>

        <!-- <div class="mobileFixedRow2">苹果下载打开后需重启APP或手机即可使用</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    linkTo() {
      window.open("https://beian.miit.gov.cn/ ", "_blank");
    },
    gotoDownLoadPage() {
      window.open(
        "https://h5.nnwmkj.com/download",
        "_blank"
      );
      // window.open(
      //   "https://a.app.qq.com/o/simple.jsp?pkgname=com.weimi.app.pq",
      //   "_blank"
      // );
      // let type = "android";
      // if (typeNum == 1) {
      //   type = "ios";
      // }
      // this.$router.push({
      //   name: "download",
      //   query: { channel: this.channel, code: this.code, type },
      // });

      // if (type == 1) {
      // 	// window.location.href = this.downHrefIos;
      // 	//window.location.href = 'https://h5.smdchat.com/ios_down';
      // 	window.location.href = 'https://apps.apple.com/app/id6462860670';
      // } else if (type == 2) {
      // 	window.location.href = this.downHrefAndroid;
      // }
    },
  },
};
</script>

<style>
.footer {
  padding: 10px 0;
  background: #03081b;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #b0b2bf;
}

.footer a {
  display: inline-flex;
  align-items: center;
  color: #b0b2bf;
}
.footer a ~ a {
  margin-left: 10px;
}
.footer-row {
  display: flex;
}
.m-download {
  display: none;
}
.dbzw {
  width: 100%;
  height: 16.5333vw;
  height: calc(16.5333vw + env(safe-area-inset-bottom));
}

.mobileFixed {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-bottom: calc(8px + env(safe-area-inset-bottom));
  background: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(4px);
}
.mobileFixedRow1 {
  width: 100%;
  display: flex;
  justify-content: space-around;
  justify-content: space-evenly;
}

.mobileFixedRow2 {
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3486ff;
  line-height: 20px;
  margin-top: 10px;
}
.mobileDownBtn {
  width: 43.4667vw;
  height: 45px;
  background: #1f2626;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff !important;
  line-height: 17px;
  text-decoration: none;
}

.mobileDownBtn_g {
  background: #20b348;
}
.mobileDownBtnimg {
  width: 6.9333vw;
  height: auto;
  margin-right: 1.3333vw;
}

@media (max-width: 768px) {
  .footer {
    display: flex;
    flex-direction: column;
  }
  .footer-row {
    flex-direction: column;
    align-items: center;
  }
  .m-download {
    display: block;
  }
}
</style>
