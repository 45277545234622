<template>
	<div>
		
		<div class="title">皮雀隐私权政策</div>
		<div class="content1">
			更新日期：2023年8月31日 <br>
			生效日期：2023年8月31日<br>
			南宁微觅科技有限公司（以下简称“我们”）深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠，我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则，目的明确原则，选择同意原则，最少够用原则，确保安全原则，主体参与原则，公开透明原则等等。同时我们承诺，我们将按业界成熟的安全解决方案，采取相应的安全保护措施来保护您的个人信息。鉴此，我们制定本《皮雀隐私权政策》（下称“本政策
			/本隐私权政策”）并提醒您：<br>
			本政策适用于皮雀产品或服务。<span class="fw600">需要特别说明的是，本政策不适用于其他第三方向您提供的服务，也不适用于已另行独立设置隐私权政策的产品或服务。</span><br>
			<span class="fw600">在使用皮雀各项产品或服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体/粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后使用相关产品或服务。您若拒绝本政策，将无法使用皮雀产品及服务。为保障软件及服务的安全运行，皮雀在相应功能点触发时会收集相关设备信息及权限。</span>如对本政策内容有任何疑问、意见或建议，您可通过皮雀提供的各种联系方式与我们联系。<br>
			本隐私权政策将帮助您了解以下内容：<br>
			一、引言<br>
			二、我们如何收集和使用您的个人信息<br>
			三、我们如何使用 Cookie 、同类技术和SDK技术<br>
			四、我们如何共享、转让、公开披露您的个人信息<br>
			五、我们如何保护您的个人信息<br>
			六、您如何管理您的个人信息<br>
			七、我们如何处理未成年人的个人信息<br>
			八、我们如何存储您的个人信息<br>
			九、本隐私权政策如何更新<br>
			十、如何联系我们<br>
			<!-- 十一、广告/我们向您发送的信息<br> -->
			十一、适用范围<br>
			十二、其他<br>
			<span class="fz18 fw600">一、引言</span><br>
			皮雀对其收集的用户信息严格保密，并建立健全用户信息保护制度，以便为每一位用户提供可靠、放心的服务：<br>

			1、清晰明确<br>

			我们将尽可能采用通俗易懂的文字为您阐释隐私政策，以便您能更清晰地了解我们如何保护和处理所收集的您的信息。<br>

			2、保障权益<br>

			我们将采取充分、合理的措施保障您的隐私权益。<br>

			3、尊重用户<br>

			我们尊重用户的选择权、知情权等权利的自由行使，允许您管理您的个人信息。<br>

			4、必要安全<br>

			我们将仅收取必要的信息，并利用这些信息为您提供安全、详实的服务。<br>

			5、及时贴心<br>

			我们将随时聆听您的询问，并尽快为您解答您的疑问。<br>

			为更好的说明，本政策中的下列概念是指：<br>

			个人常用设备信息：指包括硬件序列号、设备MAC地址、软件列表、唯一设备识别码（如IMEI/android
			ID/ICCID/MEID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等）、IP等在内的描述个人常用设备基本情况的信息。<br>

			个人位置信息：指包括行踪轨迹、精准定位信息、住宿信息、经纬度等。<br>
			<span class="fz18 fw600">二、我们如何收集和使用您的个人信息</span><br>
			个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。<br>
			个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，包括身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、14周岁以下（含）儿童的个人信息等（我们将在本隐私权政策中对具体个人敏感信息以粗体进行显著标识）。<br>
			<span class=" fw600">个人信息以及个人敏感信息包含的内容均出自于GB/T35273《个人信息安全规范》。</span><br>
			<span class=" fw600">您理解并同意：</span><br>
			<span class=" fw600">1、我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，相应的，收集使用的个人信息类型、范围等会有所区别，请以具体的产品/服务功能为准；</span><br>
			<span class=" fw600">2、我们可能将通过某些功能所收集的信息用于我们的其他服务。如我们使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您的个人信息前，再次向您告知并征得您的明示同意。</span><br>
			<span class=" fw600">3、为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。在此过程中，如果您有任何疑问、意见或建议的，您可通过我们提供的各种联系方式与我们联系，我们会尽快为您作出解答。</span><br>
			我们所提供产品或服务的功能分别收集的个人信息类型，以及收集、使用个人信息的规则（例如收集和使用个人信息的目的、方式）如下：<br>
			1、当您注册皮雀服务时，我们会收集您的<span class=" fw600">昵称、头像、性别、出生年月日、手机号码、星座，拟使用的用户名和密码</span>用于创建皮雀账户和使用社交功能。如您同意和授权我们将<span class=" fw600">您的账户信息（头像、昵称及其他页面提示的信息）</span>
			授权给第三方以使您可以便捷地实现第三方账户的注册或登录的，我们会将您同意和授权的账户信息共享给第三方。此外，
			<span class=" fw600">我们可能会根据您的授权从第三方处获取您在第三方平台的注册账号相关信息（头像、昵称以及您授权的其他信息），并与您的皮雀账户进行绑定，使您可通过第三方账户直接登录、使用我们的产品及/或服务。</span>我们将在您授权同意的范围内使用您的相关信息。
			<span class=" fw600">在注册与使用服务过程中，如果您提供以下信息补全个人资料：生日、家乡、身高、您本人的真实头像、个性标签、个人签名、地点足迹，将有助于我们给您提供更好的服务和体验。但如果您不提供这些信息，将会仅使您无法使用或参与与此相关的服务，不会影响使用皮雀产品或服务的基本功能。</span>您提供的上述信息将在您使用本服务期间持续授权我们使用。在您主动注销账号时，我们将根据适用法律法规的要求尽快使其匿名或删除您的个人信息。<br>
			2、当您注册并开始使用皮雀各项服务时，或在皮雀切换至后台运行时，为保障您正常使用我们的服务，维护我们服务的正常运行，改进及优化我们的服务体验以及保障您的帐号安全，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：
			设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、设置参数、设备配置、设备标识（IMEI/Android
			ID/OAID/IDFA/IDFV/UDID/MEID/ICCID/SIM卡IMSI信息等）、设备序列号、MAC地址、设备网络信息(运营商信息/WIFI信息/SIM卡状态/WiFi状态/WiFi列表/SSID/BSSID)、设备环境、软件列表、运行中进程信息、SDCard信息、传感器列表等软硬件特征信息）、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、蓝牙和基站等传感器信息），用于保障软件和服务的安全运行、运营的质量和效率。
			服务日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关服务日志保存。包括您的浏览、点击查看和搜索查询内容、关注分享信息、发布信息，以及IP地址、浏览器的类型、运营商信息、使用的语言、访问日期和时间。
			<span class=" fw600">请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。</span>如果我们将这类信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。 <br>
			3、当您与我们联系或提出争议纠纷处理申请时，为了保障您的账户及系统安全，<span class=" fw600">我们需要您提供必要的个人信息以核验您的用户身份。我们可能会保存您的通信/通话记录和内容（包括账号信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），</span>以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。为了提供服务及改进服务质量的合理需要，我们还可能使用您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。<span class=" fw600">此外，当您通过实名信息查询绑定的皮雀号码时，为保障账号安全，我们需要您填写您的真实姓名、身份证号并通过人脸识别的方式进行校验以确认是本人操作。我们仅对此信息进行校验，不会保留相关信息，除非皮雀依照法律法规规定另行取得您的同意。上述信息包含敏感个人信息，您可以拒绝提供，如果拒绝提供您将可能无法获得相关服务，但不影响其他功能的正常使用。</span><br>
			4、当您使用屏蔽您的手机联系人和通讯录好友功能时，您需要开启通讯录权限，我们会对你的通讯录信息进行不可逆加密，并仅收集加密后的信息。上述信息属于敏感信息，拒绝提供该信息仅会使你无法使用上述功能。<br>
			5、为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或皮雀相关协议规则的情况，<span class=" fw600">我们可能使用或整合您的用户信息、交易信息、设备信息、有关网络日志以及我们关联公司、合作伙伴取得您授权或依据法律共享给我们的信息，</span>来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。<br>
			6、您理解并同意，我们提供的服务可能需要您在您的设备中开启<span class=" fw600">您的位置信息（地理位置）、摄像头（相机）、相册（图片库）、麦克风（语音）、通讯录的访问权限，</span>
			以实现这些权限所涉及信息的收集和使用。您可以阅读皮雀设备权限清单，以了解皮雀申请和使用系统权限的情况。我们会在您使用相关服务时弹窗提示您是否要开启相应权限。<span class=" fw600">请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</span><br>
			7、您知悉并同意，对于您在使用产品及/或服务的过程中提供的您的联系方式（例如：联系电话），我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知、身份验证、安全验证、用户使用体验调研等用途。<br>
			8、<span class=" fw600">您知悉并同意，由于皮雀为即时通讯工具，需要您开启自启动以保障消息能及时送达。您可以在设备设置中进行应用启动管理，但关闭后可能导致您的消息接收延迟。</span><br>
			9、<span class=" fw600">当您使用皮雀服务时，为实现信息分享、参加相关活动等目的所必需，我们可能会调用剪切板并使用与功能相关的最小必要信息（口令、链接、统计参数等）。</span><br>
			10、<span class=" fw600">若你提供的信息中含有其他用户的个人信息，在向皮雀提供这些个人信息之前，您需确保您已经取得合法的授权。</span><br>
			11、<span class=" fw600">若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。</span><br>
			12、若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集个人信息，并向您告知共享的信息内容，且涉及敏感信息的在提供给我们使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任；同时，我们的专业安全团队对个人信息会进行安全防护（包括敏感信息分级分类、敏感信息使用的访问控制、敏感信息加密存储等）。我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。<br>
			<span class=" fw600 fz18">征得授权同意的例外</span><br>
			您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意，且我们可能不会同意您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：<br>
			1、与我们履行法律法规规定的义务相关的；<br>
			2、与国家安全、国防安全有关的；<br>
			3、与公共安全、公共卫生、重大公共利益有关的；<br>
			4、与犯罪侦查、起诉、审判和判决执行等有关的；<br>
			5、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；<br>
			6、所收集的个人信息是您自行向社会公众公开的；<br>
			7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<br>
			8、根据您的要求签订合同所必需的；<br>
			9、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；<br>
			10、为合法的新闻报道所必需的；<br>
			11、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<br>
			12、法律法规规定的其他情形。<br>
			请知悉，根据适用的法律，<span class=" fw600">若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，</span>或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善皮雀的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），<span class=" fw600">则此类处理后数据的使用无需另行向您通知并征得您的同意。</span><br>
			如我们停止运营皮雀产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。<br>
			<span class="fz18 fw600">三、我们如何使用 Cookie 、同类技术和SDK技术</span><br>
			（一）Cookie<br>
			Cookie 通常包含标识符、站点名称以及一些号码和字符。您可在浏览器中进行相应的数据清除操作。如您进行清除，您可能无法使用由我们提供的、依赖于Cookie的服务或相应功能。<br>
			（二）Cookie同类技术<br>
			除 Cookie
			外，我们还会在网站上使用其他同类技术。我们向您发送的会员短信通知或站内通知可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。<br>
			（三）SDK技术<br>

			SDK技术由与我们合作的第三方机构提供，我们仅能知悉其要求获得授权的权限。第三方机构将会需要收集您的信息，我们无法获取您的信息，除非第三方机构明确向我们披露的要收集的敏感信息。第三方机构要求获得的权限以及明确向我们披露的收集的敏感信息，详见<router-link  :to="{name:'InformationChecklist'}">《第三方信息数据共享清单》</router-link>。<br>

			第三方机构拒绝提供信息仅会使您无法使用第三方机构提供的技术所搭载的功能，但不影响您使用皮雀的其他功能。<br>
			<span class="fz18 fw600">四、我们如何共享、转让、公开披露您的个人信息</span><br>
			我们已知晓对外共享、转让、公开披露个人信息所承担的相应法律责任，我们仅会在本隐私政策约定的范围内共享、转让、公开披露您的个人信息：<br>
			（一）共享<br>
			我们不会与皮雀服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：<br>
			1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。<br>
			2、在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。<br>
			3、与关联公司间共享：<span class=" fw600">为便于我们基于关联账号共同向您提供服务，识别账号异常或保护皮雀关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会在我们的关联公司内共享。</span>我们只会共享必要的个人信息（如为便于您使用皮雀账号使用关联公司的产品或服务，我们会在关联公司内共享您必要的账户信息）且会受本隐私权政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。<br>
			4、与授权合作伙伴共享：仅为实现本隐私政策中声明的目的，我们的某些服务可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本隐私权政策声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。<br>
			5、为了使您能够使用下述服务及功能，我们的应用中会接入第三方SDK： <router-link  :to="{name:'InformationChecklist'}">《第三方信息数据共享清单》</router-link><br>
			我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。<br>
			（二）转让<br>
			我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：<br>
			1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；<br>
			2、在涉及合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。<br>
			（三）公开披露<br>
			我们仅会在以下情况下，公开披露您的个人信息：<br>
			1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；<br>
			2、如果我们确定您出现违反法律法规或严重违反皮雀相关协议规则的情况，或为保护皮雀及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或皮雀相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及皮雀已对您采取的措施。<br>
			（四）共享、转让、公开披露个人信息时事先征得授权同意的例外<br>
			以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：<br>
			1、与我们履行法律法规规定的义务相关的；<br>
			2、与国家安全、国防安全有关的；<br>
			3、与公共安全、公共卫生、重大公共利益有关的；<br>
			4、与犯罪侦查、起诉、审判和判决执行等有关的；<br>
			5、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br>
			6、您自行向社会公众公开的个人信息；<br>
			7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br>
			根据法律规定， <span class=" fw600">若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。</span><br>
			<span class="fz18 fw600">五、我们如何保护您的个人信息</span><br>
			为了保护您的信息安全，我们将严格遵守法律法规等规范性文件要求的技术措施和操作流程保护您的信息秘密，同时：<br>
			（一）我们已采取符合业界通用解决方案、合理可行的安全防护措施为用户的个人信息提供安全保障，以防止用户个人信息的非法使用、未经授权的访问和披露、泄漏、损坏和丢失。我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们会使用加密技术提高个人信息的安全性；建立受信赖的保护机制防止个人信息遭到恶意攻击；建立严格的内部控制制度和完善、充分的管理流程，部署访问控制机制，遵守最小权限原则，确保只有授权人员才可访问个人信息；开展安全和个人信息保护培训课程，加强员工对于保护个人信息重要性的认识。<br>
			（二）我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从管理机构、组织建设、制度体系、产品技术等方面多维度提升整个系统的安全性。我们建立专门的管理制度、流程和组织以保障个人信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。<br>
			（三）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。<br>
			（四）互联网并非绝对安全的环境，<span class=" fw600">我们强烈建议您不要使用非皮雀推荐的通信方式发送个人信息。</span>您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。<br>
			如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络皮雀客服，以便我们根据您的申请采取相应措施。<br>
			请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。<br>
			请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。<br>
			同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。<br>
			<span class="fz18 fw600">六、您如何管理您的个人信息</span><br>
			您可以通过以下方式访问及管理您的个人信息：<br>
			（一）查询、更正和补充您的个人信息，并获取您的个人信息副本<br>
			您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：<br>
			账户信息——如果您希望访问或编辑您的账户中的个人证件信息，手机绑定、支付信息、更改您的密码、添加安全信息或关闭您的账户等，您可以通过登录账号通过“我的”-“账号与安全”执行此类操作。<br>
			个人资料—如果您希望访问或编辑您个人资料中的昵称、头像、家乡、出生日期、身高、个人签名以及地点足迹等其他资料，您可以通过登录账户通过“我的”-“编辑资料”执行此类操作。<br>
			如果您无法通过上述路径访问该等个人信息，您可以随时通过皮雀客服与我们取得联系。我们将在15个工作日内回复您的访问请求。<br>
			对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条“（六）响应您的上述请求”中的相关安排向您提供。<br>
			（二）删除您的个人信息<br>
			您可以通过本条“（一）查询、更正或补充您的个人信息，并获得您的个人信息副本”中列明的方式删除您的部分个人信息。<br>
			在以下情形中，您可以向我们提出删除个人信息的请求：<br>
			1、如果我们处理个人信息的行为违反法律法规；<br>
			2、如果我们收集、使用您的个人信息，却未征得您的明确同意；<br>
			3、如果我们处理个人信息的行为严重违反了与您的约定；<br>
			4、如果您不再使用我们的产品或服务，或您主动注销了账号；<br>
			5、如果我们永久不再为您提供产品或服务。<br>
			若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。<br>
			<span class=" fw600">当您或我们协助您从我们的服务中删除信息后，依据相关法律法规的规定，我们可能不会立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。</span><br>
			（三）改变您授权同意的范围<br>
			每个业务功能需要一些基本的个人信息才能得以完成，对于额外个人信息的收集和使用，您可以在更多-设置或与皮雀客服联系给予或收回您的授权同意。<br>
			当您收回同意后，我们将不再处理相应的个人信息。<span class=" fw600">但您收回同意的决定，不会影响此前基于您的授权已开展的个人信息处理。</span><br>
			（四）个人信息主体注销账户<br>
			您可以自行在“帐号注销”页面提交账户注销申请，在您主动注销账户之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理。<br>
			（五）约束信息系统自动决策<br>
			在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害皮雀商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。<br>
			（六）响应您的上述请求<br>
			为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在15个工作日内作出答复。如您不满意，还可以通过皮雀客服发起投诉。<br>
			对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。<br>
			在以下情形中，按照法律法规要求，我们将无法响应您的请求：<br>
			1、与国家安全、国防安全有关的；<br>
			2、与公共安全、公共卫生、重大公共利益有关的；<br>
			3、与犯罪侦查、起诉、审判和执行判决等有关的；<br>
			4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；<br>
			5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；<br>
			6、涉及商业秘密的。<br>
			<span class="fz18 fw600">七、我们如何处理未成年人的个人信息</span><br>
			任何18岁以下的未成年人均不得创建自己的用户账户及/或使用我们的服务。<br>
			您理解并承诺，您向我们提供的注册信息是真实的，如果我们通过平台的相关机制识别您的账号具有未成年使用风险，我们将有权不向您提供皮雀平台的部分服务及功能。如您不是未成年人，请您申诉进行实名认证，认证通过后，我们将您的账号立即恢复至原使用状态。<br>
			<span class="fz18 fw600">八、我们如何存储您的个人信息</span><br>
			我们在中华人民共和国境内运营中收集和产生的您的个人信息，存储在中国境内。<br>
			<span class=" fw600">一般而言，我们自您使用服务时获取到您个人信息之日起至您注销账户后6个月内会存储并保留您的个人信息。</span><br>
			<span class=" fw600">在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。</span><br>
			当我们的产品或服务发生停止运营的情形时，我们将以推送通知、公告等形式通知您，并在合理的期限内删除您的个人信息或进行匿名化处理。<br>
			<span class="fz18 fw600">九、本隐私权政策如何更新</span><br>
			我们的隐私权政策可能变更。如果发生业务功能变更、使用目的变更、个人信息保护相关负责人联络方式变更等情形，我们将相应修订隐私政策。<br>
			未经您明确同意，我们不会限制您按照本隐私权政策所应享有的权利。对于重大变更，我们会提供显著的通知（包括我们会通过皮雀公示的方式进行通知）。<br>
			本政策所指的重大变更包括但不限于：<br>
			1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br>
			2、我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；<br>
			3、个人信息共享、转让或公开披露的主要对象发生变化；<br>
			4、您参与个人信息处理方面的权利及其行使方式发生重大变化；<br>
			5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；<br>
			6、个人信息安全影响评估报告表明存在高风险时。<br>
			我们还会将本隐私权政策的旧版本存档，供您查阅。<br>
			<span class="fz18 fw600">十、如何联系我们</span><br>
			我们建立了个人信息保护专线，如您对本政策或相关事宜有疑问或建议，或者对使用我们产品过程中生成的个人信息需要更正、查询，或者有和个人信息相关的投诉、建议、疑问的，可以通过如下投诉渠道与我们取得联系：<br>
			1、您可在皮雀APP中“我的”-“帮助与反馈”-“咨询客服”通过在线客服与我们联系；<br>
			2、投诉举报邮箱：pique@myouxun.com　；<br>
			3、联系电话：020-36139859<br>
			4、纸质文件邮寄地址：广东省广州市白云区嘉禾创意园6栋202　法务部收。<br>
			我们将妥善受理并及时反馈您的申诉，并在验证您的用户身份后的十五个工作日内予以回复。<br>
			如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益， 您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。<br>
			<span class="fz18 fw600">十一、适用范围</span><br>
			本隐私权政策适用于由南宁微觅科技有限公司提供的所有服务，包括皮雀客户端、网站等，但不适用于有单独的隐私权政策且未纳入本隐私权政策的第三方产品或服务。<br>
			<span class="fz18 fw600">十二、其他</span><br>
			本“隐私政策”中的标题仅为方便及阅读而设，并不影响本隐私政策中任何规定的含义或解释。<br>
			本“隐私政策”的版权为我们所有，在法律允许的范围内，我们拥有解释和修改的权利。<br>
			<div style="text-align: right;">南宁微觅科技有限公司</div>

			历史版本<br>
			时间 链接<br>
			2023.8.31 皮雀用户隐私政策<br>
		</div>
	</div>
</template>
<script>

export default {
	components: {
	},
	data() {
		return {
			pageindex: '1',
		}
	},
	methods: {
		changPage(e) {
			// console.log(e);
			this.pageindex = e
		}
	},
};

</script>
<style scoped>
.title{
	text-align: center;
	font-size: 28px;
	font-weight: 600;
}
.content1{
	line-height: 38px;
}
.fw600{
	font-weight: 600;

}
.fz18{
	font-size: 20px;
}
.fcred{
	color: red;
}
</style>
  
  