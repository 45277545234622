<template>
  <div>
    <div class="navbar-placeholder"></div>
    <div class="m-header">
      <div class="navbar">
        <div class="navbar-content">
          <img
            class="navbar-brand"
            src="@/images/phone/logo.png"
          />
          <div class="navbar-toggle" @click="handleToggle">
            <img v-show="!show" src="@/images/ic_menu_zhankai.png" />
            <img v-show="show" src="@/images/ic_menu_shouqi.png" />
          </div>
        </div>
        <div class="navbar-collapse" :class="{ show }">
          <div class="navbar-nav">
            <div
              v-for="tab in tabs"
              :key="tab.path"
              class="navbar-nav-item"
              :class="{
                active: Array.isArray(tab.name)
                  ? tab.name.includes(currentTab)
                  : currentTab === tab.name,
              }"
              @click="handleClick(tab)"
            >
              {{ tab.label }}
            </div>
          </div>
        </div>
      </div>
      <div class="shade" :class="{ show }" @click="show = false"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentTab: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
      tabs: [
        { label: "首页", path: "/", name: ["1", "2", "3"] },
        { label: "最新动态", path: "/news", name: "4" },
      ],
    };
  },
  methods: {
    handleToggle() {
      this.show = !this.show;
    },
    handleClick(tab) {
      this.show = false;
      this.$router.push(tab.path);
    },
  },
};
</script>

<style scoped>
.m-header {
  display: none;
}
.navbar-placeholder {
  display: none;
}
@media (max-width: 768px) {
  .navbar-placeholder,
  .m-header {
    display: block;
  }
}
.m-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.navbar-placeholder {
  height: 50px;
}
.navbar {
  position: relative;
  z-index: 10;
}
.navbar-content {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(35, 48, 46, 0.04);
}
.navbar-brand {
  width: 89px;
  height: 32px;
  margin-left: 16px;
  object-fit: contain;
}
.navbar-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
}
.navbar-toggle img {
  width: 20px;
  height: 20px;
}
.navbar-collapse {
  position: absolute;
  top: 100%;
  transform: translateY(-100%);
  width: 100%;
  background-color: #fff;
  transition: 300ms;
}
.navbar-collapse.show {
  transform: none;
}
.navbar-nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-size: 14px;
  color: #01071a;
}
.navbar-nav-item.active {
  color: var(--primary-color);
}
.navbar-nav-item ~ .navbar-nav-item {
  border-top: 1px solid #eef2f2;
}
.shade {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: none;
  opacity: 0;
  transition: opacity 300ms;
  background: rgba(0, 0, 0, 0.5);
}
.shade.show {
  opacity: 1;
  pointer-events: auto;
}
</style>
