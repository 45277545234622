<template>
  <div class="main" ref="main">
    <Header :showFixedNav="true" currentTab="1" />

    <!-- 内容 -->
    <div class="mobileContent">
      <el-image :src="require(`@/images/phone/homepage.jpg`)" fit="cover" />
      <div class="videoBox">
        <video
          :poster="require(`@/images/phone/video-post.jpg`)"
          ref="videoPlayer"
          muted
          playsinline
          webkit-playsinline
          autoplay
          loop
        >
          <source :src="require('@/images/openVd.mp4')" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <!-- <div class="mapItem">
				<div id="gao-de-map" style="height: 100%;width: 100%;border-radius: 16px 16px 0 0;"></div>
			</div> -->
    </div>
  </div>
</template>
<script>
// import { getVersionInfo } from '@/api/system'

import Header from "@/components/Header.vue";

export default {
  components: {
    Header,
  },
  name: "MobileMain",
  props: {
    index: {
      type: String,
      default: "1",
    },
  },

  data() {
    return {
      downHrefIos: "",
      downHrefAndroid: "",
      isSafari: false,

      pageIndex: 1,

      headType: 1,
      showNav: false,
      mobileNavItem: ["首页", "安全聊天", "最新动态", "关于事密达", "安装教程"],
      showDownload: false,
      code: "",
      channel: "",
      showLoading: false,
    };
  },

  mounted() {
    import("@amap/amap-jsapi-loader").then((AMapLoader) => {
      // use code
      // 设置安全密钥
      window._AMapSecurityConfig = {
        securityJsCode: "6933d28ebb86730a96d1f50277633000",
      };
      AMapLoader.load({
        key: "bba0b0bc3ac796da8a4d682489f0cdad", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("gao-de-map", {
            //设置地图容器id
            viewMode: "2D", //是否为3D地图模式
            zoom: 16, //设置地图显示的缩放级别
            center: [108.313547, 22.789868], //初始化地图中心点位置
          });
          var marker = new AMap.Marker({
            position: [108.313547, 22.789868],
            map: this.map,
          });
          marker.setLabel({
            offset: new AMap.Pixel(-5, -10), // 设置文本标注偏移量
            content: "<div>广西南宁江南万达广场C16号</div>", // 设置文本标注内容
            direction: "top", // 设置文本标注方向
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
    this.getPageData();
    if (
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent)
    ) {
      this.isSafari = true;
    }
    this.channel = this.getQueryVariable("channel");
    this.code = this.getQueryVariable("code");
    if (this.channel != "") {
      sessionStorage.setItem("channel", this.channel);
      sessionStorage.setItem("code", this.code);
    }
    document.addEventListener("click", this.handleUserClick);
    document.addEventListener("touchstart", this.handleUserClick);
  },
  destroyed() {
    document.removeEventListener("click", this.handleUserClick);
    document.removeEventListener("touchstart", this.handleUserClick);
  },
  methods: {
    linkTo() {
      window.open("https://beian.miit.gov.cn/ ", "_blank");
      // window.location.href = 'https://beian.miit.gov.cn/ '
    },
    handleUserClick() {
      // 用户点击时，播放视频
      this.$refs.videoPlayer.play();

      // 然后取消监听
      document.removeEventListener("click", this.handleUserClick);
      document.removeEventListener("touchstart", this.handleUserClick);
    },

    getQueryVariable(value) {
      var query = window.location.search.substring(1);
      query = query.split("&");
      var data = {};
      for (var i = 0; i < query.length; i++) {
        var item = query[i].split("=");
        data[item[0]] = item[1];
      }
      if (value) {
        return data[value] ? data[value] : "";
      }
      return data;
    },
    closeNav() {
      this.showNav = false;
    },

    showNavBtn() {
      this.showNav = !this.showNav;
    },
    changePage(i) {
      this.pageIndex = i;
      this.showNav = false;
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth", // 平滑滚动到顶部
      });
    },

    getPageData() {
      return;
      getVersionInfo({ agent: "all" }).then((res) => {
        this.downHrefIos = res.data.ios.download_url;
        this.downHrefAndroid = res.data.android.download_url;
        this.initULink(res.data.umeng_link_id);
      });
    },

    gotoxieyi() {
      window.location.href =
        "https://h5.smdchat.com/webRule?key=privacy_policy_ios_mj";
    },

    initULink(id) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://g.alicdn.com/jssdk/u-link/index.min.js";
      document.getElementsByTagName("head")[0].appendChild(script);
      let data = this.parseUrlParams();
      script.onload = function () {
        window.ULink({
          id,
          data: data,
          selector: "#downloadButton",
          // auto:true,
          timeout: 2000,
          lazy: false,
          useOpenInBrowerTips: "default",
          proxyOpenDownload: function (defaultAction, LinkInstance) {
            // console.log(9999)
            // console.log(LinkInstance.solution.type)

            if (LinkInstance.solution.type === "scheme") {
              // qq或者微信环境特殊处理下

              if (ULink.isWechat || ULink.isQQ) {
                // 在qq或者微信环境执行内置逻辑，具体内置逻辑为:当设置了useOpenInBrowerTips字段时，qq&&微信&&scheme时，启用蒙层提示去浏览器打开

                defaultAction();
              } else {
                window.location.href = LinkInstance.solution.downloadUrl;
              }
            } else if (LinkInstance.solution.type === "universalLink") {
              // universalLink 唤起应当由服务端提供一个带重定向到appstore的universallink地址。因此，此处不应写逻辑，友盟已于6月2日上线universalLink生成及重定向功能。
            }
          },
        });
      };
    },
    parseUrlParams(e) {
      var t = (e = e || window.location.href).indexOf("?") + 1,
        n = (e || "?").substring(t).replace(/\+/g, "%20"),
        r = n.indexOf("#");
      0 <= r && (n = n.substring(0, r));
      for (var i = n.split("&"), o = {}, a = 0; a < i.length; a++) {
        var s = i[a].split("="),
          c = decodeURIComponent(s[0] || ""),
          l = decodeURIComponent(s[1] || "");
        c &&
          l &&
          (void 0 === o[c]
            ? (o[c] = l)
            : "object" == typeof o[c]
            ? o[c].push(l)
            : (o[c] = [o[c], l]));
      }
      return o;
    },
  },
};
</script>

<style>

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.mobileContent {
  display: flex;
  position: relative;
  width: 100vw;
  height: 1689.6vw;
}
.videoBox {
  position: absolute;
  z-index: 99;
  top: 33.3333vw;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding: 4px;
  border-radius: 4.5333vw;
  background-color: rgba(255,255,255,0.6);
  box-shadow: 0px 8px 25px 0px rgba(160,132,255,0.2);
}
.videoBox video {
  width: 41.5467vw;
  height: 89.8293vw;
  object-fit: fill;
  border-radius: inherit;
}
.mapItem {
  position: absolute;
  left: 50%;
  top: 977.86667vw;
  transform: translateX(-50%);
  width: 91.5vw;
  height: 44.366vw;
  border-radius: 16px 0px 0px 16px;
}
</style>
