<template>
  <div class="container">
    <Header :showFixedNav="true" :showLine="false" />

    <div
      class="content"
      :style="{
        backgroundImage: `url(${require('@/images/bg_zuixinzixun.jpg')}`,
      }"
    >
      <iframe
        src="https://tb.53kf.com/code/app/9a317e6fb2cd880d93f6ba3695002c041/1?"
        frameborder="0"
      ></iframe>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { recordLogger } from "@/api/common";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {
    this.report();
  },
  methods: {
    report() {
      recordLogger({
        access_key: "web_kefu",
      });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 15px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.content iframe {
  width: 800px;
  height: 600px;
}
</style>
