<template>
  <div class="empty">
    <img class="empty-icon" src="@/images/img_none_sousuo.png" />
    <div class="empty-title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.empty-icon {
  width: 144px;
  height: 146px;
}
.empty-title {
  margin-top: 15px;
  font-weight: 400;
  font-size: 13px;
  color: #9fa7a7;
  line-height: 16px;
}
</style>
