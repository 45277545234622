<template>
  <div>
    <div class="navbar-placeholder"></div>
    <div class="m-header">
      <div class="navbar">
        <div class="navbar-content">
          <img
            class="navbar-brand"
            src="@/views/aimi/assets/logo.jpg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentTab: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.m-header {
  display: none;
}
.navbar-placeholder {
  display: none;
}
@media (max-width: 768px) {
  .navbar-placeholder,
  .m-header {
    display: block;
  }
}
.m-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.navbar-placeholder {
  height: 50px;
}
.navbar {
  position: relative;
  z-index: 10;
}
.navbar-content {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(35, 48, 46, 0.04);
}
.navbar-brand {
  width: auto;
  height: 40px;
  margin-left: 16px;
  object-fit: contain;
}
</style>
