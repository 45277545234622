<template>
  <div v-show="scrollToTopVisible" class="scroll-to-top-wrapper">
    <div class="scroll-to-top" @click="scrollToTop">
      <img src="@/images/ic_fanghuidingbu@2x.png" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollToTopVisible: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandler);
  },
  unmounted() {
    window.removeEventListener("scroll", this.scrollHandler);
  },
  methods: {
    scrollHandler() {
      this.scrollToTopVisible = window.scrollY > 500;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.scroll-to-top-wrapper {
  position: sticky;
  bottom: 48px;
  max-width: 1400px;
  height: 0;
  margin: 0 auto;
}
.scroll-to-top {
  position: sticky;
  margin-left: auto;
  right: 20px;
  width: 48px;
  height: 48px;
  background: var(--primary-color);
  border-radius: 50%;
  cursor: pointer;
  transform: translateY(-24px);
}
.scroll-to-top img {
  display: block;
  width: 100%;
  height: 100%;
}
@media (max-width: 768px) {
  .scroll-to-top-wrapper {
    bottom: 95px;
    bottom: calc(95px + env(safe-area-inset-bottom));
  }
}
</style>
