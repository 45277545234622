<template>
  <div class="container">
    <Header :showFixedNav="true" currentTab="4" />

    <div class="breadcrumb">
      <div class="breadcrumb-item" @click="navigateToNews">皮雀动态信息</div>
      <div class="breadcrumb-item active">动态详情</div>
    </div>
    <div class="content">
      <div class="content-inner">
        <div class="header">
          <div class="title">{{ data.title }}</div>
          <div class="time">{{ data.created_at }}</div>
        </div>
        <div class="body" v-html="data.content"></div>
      </div>
    </div>

    <ScrollToTop />
    <Footer style="margin-top: 48px" />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ScrollToTop from "@/components/ScrollToTop.vue";
import { getNewsDetail, recordViewLogger } from "@/api/cms";

export default {
  components: {
    Header,
    Footer,
    ScrollToTop,
  },
  data() {
    return {
      newId: "",
      data: {},
    };
  },
  created() {
    this.newId = parseInt(this.$route.params.id) || 0;
    this.getDetail();
  },
  mounted() {
    recordViewLogger({
      id: this.newId,
    });
  },
  methods: {
    navigateToNews() {
      this.$router.push("/news");
    },
    getDetail() {
      getNewsDetail({
        id: this.newId,
      }).then((res) => {
        this.data = res.data;
      });
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
  background: #f2fafa;
}

.breadcrumb {
  display: flex;
  max-width: 1200px;
  margin: 16px auto;
  padding: 0 15px;
}
.breadcrumb-item {
  position: relative;
  font-size: 12px;
  color: #9fa7a7;
  line-height: 17px;
  cursor: pointer;
}
.breadcrumb-item.active {
  color: #1f2626;
  cursor: default;
}
.breadcrumb-item ~ .breadcrumb-item {
  margin-left: 19px;
}
.breadcrumb-item ~ .breadcrumb-item::before {
  position: absolute;
  right: 100%;
  transform: translateX(-6px);
  content: ">";
  pointer-events: none;
}
.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}
.content-inner {
  box-sizing: border-box;
  min-height: calc(100vh - 120px - 86px - 49px - 48px);
  padding: 32px 56px 56px;
  border-radius: 16px;
  background: #ffffff;
}
.header {
  padding-bottom: 24px;
  border-bottom: 1px solid #eef2f2;
}
.title {
  font-weight: 500;
  font-size: 32px;
  color: #1f2626;
  line-height: 45px;
  text-align: center;
}
.time {
  margin-top: 12px;
  text-align: center;
  font-size: 14px;
  color: #9fa7a7;
  line-height: 20px;
}
.body {
  margin-top: 24px;
  font-weight: 400;
  font-size: 16px;
  color: #464b4b;
  line-height: 28px;
  word-break: break-all;
}
.body ::v-deep img {
  display: block;
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .breadcrumb {
    margin: 12px auto;
  }
  .content-inner {
    padding: 20px;
  }
  .header {
    border: none;
    padding-bottom: 0;
  }
  .title {
    font-size: 19px;
    line-height: 30px;
    text-align: left;
  }
  .time {
    margin-top: 8px;
    text-align: left;
  }
  .body {
    margin-top: 16px;
    line-height: 30px;
  }
}
</style>
