import request from '@/utils/request';

// 获取资讯列表
export function getNewsList(params) {
  return request({
    url: '/web/cms/getList',
    method: 'get',
    params,
  });
}

// 获取资讯详情
export function getNewsDetail(params) {
  return request({
    url: '/web/cms/getInfo',
    method: 'get',
    params,
  });
}

// 记录资讯访问日志
export function recordViewLogger(data) {
  return request({
    url: '/web/cms/recordViewLogger',
    method: 'post',
    data,
  });
}