<template>
  <div>
    <div class="headerRow" :class="{
      headerRowFixed: showFixedNav,
      anim: headerBgAnim
    }">
      <div class="header-content">
        <el-image
          style="width: 176px; height: 120px"
          :src="require('@/images/home/img_logo_pq.png')"
        ></el-image>
        <div class="tabs">
          <div
            v-for="tab in tabs"
            :key="tab.name"
            :data-tab-name="tab.name"
            class="tabs-tab"
            :class="{ active: tab.name === innerCurrentTab }"
            :data-href="tab.target"
            @click="handleClick(tab)"
          >
            {{ tab.label }}
          </div>
          <div class="header-kf" @click="navigateToKf">
            <img src="@/images/ic_kefu.png" />
            <span>联系客服</span>
          </div>
          <div
            v-show="showLine"
            class="tabs-line"
            :class="{ anim: tabsLineAnim }"
            ref="tabLine"
          ></div>
        </div>
      </div>
    </div>

    <div class="header-placeholder"></div>
    <div class="headerBorder"></div>

    <MHeader :currentTab="currentTab"></MHeader>
  </div>
</template>

<script>
import MHeader from "@/components/MHeader.vue";

export default {
  components: {
    MHeader,
  },
  name: "AppHeader",
  props: {
    showFixedNav: {
      type: Boolean,
      default: false,
    },
    currentTab: {
      type: String,
      default: "",
    },
    showLine: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tabs: [
        {
          label: "下载皮雀APP",
          name: "1",
          path: "/?hash=download",
          target: "#download",
        },
        {
          label: "皮雀功能介绍",
          name: "2",
          path: "/?hash=introduce",
          target: "#introduce",
        },
        {
          label: "关于我们",
          name: "3",
          path: "/?hash=about",
          target: "#about",
        },
        { label: "最新动态", name: "4", path: "/news" },
      ],
      tabsLineAnim: false,
      headerBgAnim: false,
    };
  },
  computed: {
    innerCurrentTab: {
      get() {
        return this.currentTab;
      },
      set(newValue) {
        this.$emit("update:currentTab", newValue);
      },
    },
  },
  watch: {
    innerCurrentTab() {
      this.setTabLineOffset();
    },
    $route: {
      handler() {
        if (
          this.$route.path === "/" &&
          ["introduce", "about"].includes(this.$route.query.hash)
        ) {
          this.headerBgAnim = false
        } else {
          this.headerBgAnim = true
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.setTabLineOffset();
    const hash = this.$route.query.hash;
    if (hash) {
      this.scrollToHash("#" + hash, true);
    }
  },
  methods: {
    setTabLineOffset() {
      const tabEl = document.querySelector(
        `[data-tab-name="${this.innerCurrentTab}"]`
      );
      if (tabEl) {
        const offset = tabEl.offsetLeft + tabEl.offsetWidth / 2;
        this.$refs.tabLine.style.setProperty(
          "transform",
          `translateX(${offset}px) translateX(-50%)`
        );
        setTimeout(() => {
          this.tabsLineAnim = true;
        }, 300);
      }
    },

    handleClick(tab) {
      this.$router.push(tab.path);
      if (tab.target) {
        this.scrollToHash(tab.target);
      }
    },

    scrollToHash(hash, instant) {
      const target = document.querySelector(hash);

      if (target) {
        window.scrollTo({
          top: window.scrollY + target.getBoundingClientRect().top - 120,
          behavior: instant ? "instant" : "smooth",
        });
      }
    },

    navigateToKf() {
      this.$router.push("/staff-service");
    },
  },
};
</script>

<style scoped>
.headerRow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.headerRow.anim {
  transition: 500ms ease-in-out;
}
.header-placeholder {
  height: 120px;
}
@media (max-width: 768px) {
  .header-placeholder,
  .headerRow,
  .headerBorder {
    display: none;
  }
}
.header-content {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  height: 120px;
  margin: 0 auto;
  transition: 500ms ease-in-out;
  -webkit-tap-highlight-color: transparent;
}
.tabs {
  position: relative;
  display: flex;
  align-items: center;
}

.tabs-tab {
  box-sizing: border-box;
  display: inline-block;
  height: 40px;
  padding: 0 20px;
  line-height: 40px;
  font-size: 18px;
  color: #1f2626;
  opacity: 0.8;
  cursor: pointer;
}
.tabs-tab:first-child {
  padding-left: 0;
}
.tabs-tab:last-child {
  padding-right: 0;
}

.tabs-tab.active {
  font-weight: 600;
  opacity: 1;
}

.tabs-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 16px;
  height: 2px;
  background-color: #1f2626;
}
.tabs-line.anim {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.headerBorder {
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}

.headerRowFixed {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 199;
}

.header-kf {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 122px;
  height: 42px;
  margin-left: 36px;
  color: #fff;
  background: #3f88ff;
  border-radius: 22px;
  cursor: pointer;
}
.header-kf img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
</style>
