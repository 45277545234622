<template>
  <div class="main" ref="main">
    <div class="pcBox">
      <div
        id="download"
        class="home pageItem"
        :style="{
          backgroundImage:
            'url(' + require('@/views/aimi/assets/img_guanwang_bg.jpg') + ')',
        }"
      >
        <Header :showFixedNav="showFixedNav" :currentTab.sync="currentTab" />

        <div class="downLoad">
          <div class="downLoadBtn">
            <el-image :src="require('@/images/home/btn_android.png')" />
            <div class="downLoadQrc">
              <el-image :src="require('@/views/aimi/assets/download.png')" />
            </div>
          </div>
        </div>
        <div
          class="phoneBox"
          ref="phoneBox"
          :style="{
            backgroundImage: `url(${require('@/images/home/img_pq_zhutu.png')}`,
          }"
        >
          <img src="@/images/home/img_pqzt_dalinzi.png" />
          <img src="@/images/home/img_pqzt_fujin.png" />
          <img src="@/images/home/img_pqzt_zhenren.png" />
          <img src="@/images/home/img_pqzt_paipai.png" />
          <img src="@/images/home/img_pqzt_youhua.png" />
          <img src="@/images/home/img_pqzt_mimi.png" />
          <img src="@/images/home/img_pqzt_putong.png" />
          <img src="@/images/home/img_pqzt_qingbaoju.png" />
          <img src="@/images/home/img_pqzt_moqiyibai.png" />
        </div>
      </div>

      <div
        id="contact"
        class="Map pageItem"
        ref="contact"
        :style="{
          backgroundImage:
            'url(' + require('@/images/home/img_pqbg_lianxi.jpg') + ')',
        }"
      >
        <div class="mapBox">
          <div class="companyInfo">
            <el-image
              class="companyInfoTitle"
              :src="require('@/images/home/img_lianxi_biaoti.png')"
            />
            <div class="companyCardBox">
              <div class="companyCard">
                <div class="companyCardTitle">广州友迅网络科技有限公司</div>
                <div class="companyCardHeng"></div>
                <div class="companyCardRow">
                  联系邮箱
                  <div class="mgl16">pique@myouxun.com</div>
                </div>
                <div class="companyCardRow">
                  联系方式
                  <div class="mgl16">020-37324930</div>
                </div>
                <div class="companyCardRow">
                  公司地址
                  <div class="mgl16">广州市白云区尖彭路371号A栋210室</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobileBox">
      <MHeader />
      <el-image :src="require('@/views/aimi/assets/m-first-page.jpg')" />
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import MHeader from "./MHeader.vue";

export default {
  components: {
    Header,
    Footer,
    MHeader,
  },
  name: "AppMain",
  props: {
    index: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      currentTab: "1",
      map: null,
      showFixedNav: false,
      showQrcodeIos: false,
      showQrcodeAd: false,
    };
  },
  beforeMount() {
    document.title = '爱觅官方网站-Crush也在玩的社交APP'
    document.querySelector('[rel="icon"]').href = '/aimi.ico'
  },
  mounted() {
    this.runPhoneAnim();

    window.addEventListener("scroll", this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    runPhoneAnim() {
      const TOP = 90;
      const GAP_TOP = 64;
      const GAP_EDGE = 102;

      const box = this.$refs.phoneBox;
      const imgs = box.querySelectorAll("img");
      const boxRect = box.getBoundingClientRect();
      const boxWidth = boxRect.width;
      const boxHeight = boxRect.height;
      const boxCenterX = boxWidth / 2;
      const boxCenterY = boxHeight / 2;
      const imgWidth = 203;
      const imgHeight = 84;

      const style = document.createElement("style");
      document.head.appendChild(style);
      const sheet = style.sheet;

      const baseSelector = "phone-img";

      function setImgStyle(index, img, top, left, offsetX, offsetY) {
        const originX = offsetX;
        const originY = offsetY;
        const fromX = originX * 0.1;
        const fromY = originY * 0.1;
        const toX = -fromX;
        const toY = -fromY;
        img.style.setProperty("left", left + "px");
        img.style.setProperty("top", top + "px");
        img.style.setProperty(
          "transform",
          `translate(${originX}px, ${originY}px)`
        );
        const selector = `${baseSelector}-${index}`;

        const enterFrom = `from { transform: translate(${originX}px, ${originY}px)}`;
        const enterTo = `to { transform: translate(${fromX}px, ${fromY}px)}`;
        const enterKeyframeRule = `@keyframes ${selector}-enter { ${enterFrom} ${enterTo} }`;

        const from = `from { transform: translate(${fromX}px, ${fromY}px)}`;
        const to = `to { transform: translate(${toX}px, ${toY}px)}`;
        const keyframeRule = `@keyframes ${selector} { ${from} ${to} }`;

        const enterDelay = index * 0.1 + 0.2;
        const enterDuration = 2;
        const delay = enterDelay + enterDuration;
        const duration = 2;
        const enterAnimation = `${selector}-enter ${enterDuration}s ${enterDelay}s`;
        const animation = `${selector} ${duration}s ease-out ${delay}s infinite alternate`;
        const styleRule = `.${selector} { animation: ${enterAnimation}, ${animation} }`;

        sheet.insertRule(enterKeyframeRule);
        sheet.insertRule(keyframeRule);
        sheet.insertRule(styleRule);
        img.classList.add(selector);
      }

      for (let i = 0; i < imgs.length; i++) {
        const img = imgs[i];
        const top =
          TOP + ((i % 2 !== 0 ? i - 1 : i) / 2) * (GAP_TOP + imgHeight);
        const left =
          i % 2 === 0
            ? i % 4 === 0
              ? GAP_EDGE
              : 0
            : boxWidth - imgWidth - ((i - 1) % 4 === 0 ? GAP_EDGE : 0);
        const imgCenterX = left + imgWidth / 2;
        const imgCenterY = top + imgHeight / 2;
        const offsetX = boxCenterX - imgCenterX;
        const offsetY = boxCenterY - imgCenterY;
        setImgStyle(i, img, top, left, offsetX, offsetY);
      }
    },

    handleScroll() {
      if (window.scrollY > 0 && this.showFixedNav == false) {
        this.showFixedNav = true;
      } else if (window.scrollY === 0) {
        this.showFixedNav = false;
      }

      const navHeight = 120 + 10;
      const isScrollBottom =
        window.scrollY + window.innerHeight + 100 >=
        document.documentElement.scrollHeight;
      if (
        this.$refs.contact.getBoundingClientRect().top < navHeight ||
        isScrollBottom
      ) {
        this.currentTab = "2";
      } else {
        this.currentTab = "1";
      }
    },
  },
};
</script>

<style scoped>
.pageItem {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 1200px;

  position: relative;
}

.headerBorder {
  width: 100vw;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 120px;
  left: 0;
}

.downLoad {
  position: absolute;
  top: 148px;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  display: flex;
  cursor: pointer;
}

.downLoadBtn {
  position: relative;
  width: 194px;
  height: 64px;
  margin: 0 12px;
}
.downLoadBtn:hover .downLoadQrc {
  display: block;
}

.downLoadQrc {
  position: absolute;
  top: 100%;
  left: 0;
  width: 194px;
  height: 194px;
  background: #ffffff;
  box-shadow: 0px 10px 30px 0px rgba(33, 159, 162, 0.2);
  border-radius: 8px;
  overflow: hidden;
  z-index: 2;
  display: none;
  align-items: center;
  justify-content: center;
}

.downLoadQrcR {
  transform: translateX(12px);
}

.downLoadQrcS {
  opacity: 1;
}

.phoneBox {
  position: absolute;
  top: 260px;
  left: 50%;
  transform: translateX(-50%);
  width: 852px;
  height: 916px;
  background-position: center;
  background-size: 100% 100%;
}

.phoneBox img {
  position: absolute;
  width: 203px;
  height: 84px;
}

.tipsImg {
  position: absolute;
  top: 1376px;
  left: 50%;
  transform: translateX(-50%);
  width: 1200px;
  height: 367px;
}

.Map {
  height: 594px;
  width: 100vw;
  position: relative;
}

.mapBox {
  width: 1200px;
  height: 450px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 16px;
}

.mapItem {
  width: 600px;
  height: 450px;
  background-color: #626a76;
  border-radius: 16px 0px 0px 16px;
}

.otherMsg {
  background-color: #fff;
  width: 600px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0px 16px 16px 0px;
}

.otherMsgT {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #343940;
  line-height: 28px;
}

.otherMsgH {
  width: 24px;
  height: 4px;
  background: var(--primary-color);
  margin-top: 18px;
  margin-bottom: 40px;
}

.otherCard {
  width: 544px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.otherCardItem {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #f4f8fd;
  width: 168px;
  height: 216px;
}

.otherCardImg {
  width: 48px;
  height: 48px;
  margin-top: 48px;
  margin-bottom: 24px;
}

.otherCardQrcode {
  width: 88px;
  height: 88px;
  margin-top: 26px;
  margin-bottom: 16px;
}

.otherCardName {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #343940;
  line-height: 17px;
  margin-bottom: 8px;
}

.otherCardName2 {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: var(--primary-color);
  line-height: 20px;
  cursor: pointer;
}

.headerRowFixed {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 199;
}

.pcBox {
  display: block;
}
.mobileBox {
  display: none;
}
@media screen and (max-width: 768px) {
  .pcBox {
    display: none;
  }
  .mobileBox {
    display: flex;
  }
}
</style>

<style scoped>
.companyInfo {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.companyInfoTitle {
  margin-top: 32px;
  width: 391px;
  height: 80px;
}
.companyCardBox {
  margin-top: 32px;
  display: flex;
  width: 100%;
  justify-content: center;
}
.companyCard {
  width: 460px;
  height: 216px;
  padding: 24px;
  box-sizing: border-box;
  margin: 0 10px;
  border: 1px solid #eef2f2;

  border-radius: 12px;
}

.companyCardTitle {
  font-size: 18px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #343940;
  line-height: 25px;
}
.mgl16 {
  margin-left: 16px;
}
.companyCardHeng {
  width: 24px;
  height: 4px;
  background: var(--primary-color);
  margin-top: 12px;
  margin-bottom: 24px;
}
.companyCardRow {
  display: flex;
  margin-top: 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #343940;
  line-height: 20px;
}
</style>
