<template>
  <div class="main" ref="main">
    <!-- 下载皮雀APP -->
    <div
      id="download"
      class="home pageItem"
      :style="{
        backgroundImage:
          'url(' + require('@/images/home/img_guanwang_bg.jpg') + ')',
      }"
    >
      <Header :showFixedNav="showFixedNav" :currentTab.sync="currentTab" />

      <div class="downLoad">
        <div
          class="downLoadBtn"
          @mouseenter="mouseenter(1)"
          @mouseleave="mouseleave(1)"
        >
          <el-image :src="require('@/images/home/btn_ios.png')" />
        </div>
        <div
          class="downLoadBtn"
          @mouseenter="mouseenter(2)"
          @mouseleave="mouseleave(2)"
        >
          <el-image :src="require('@/images/home/btn_android.png')" />
        </div>
      </div>
      <div class="downLoadQrc" :class="showQrcodeIos ? 'downLoadQrcS' : ''">
        <el-image :src="require('@/images/home/download-qrcode2.png')" />
      </div>
      <div
        class="downLoadQrc downLoadQrcR"
        :class="showQrcodeAd ? 'downLoadQrcS' : ''"
      >
        <el-image :src="require('@/images/home/download-qrcode2.png')" />
      </div>
      <div
        class="phoneBox"
        ref="phoneBox"
        :style="{
          backgroundImage: `url(${require('@/images/home/img_pq_zhutu.png')}`,
        }"
      >
        <img src="@/images/home/img_pqzt_dalinzi.png" />
        <img src="@/images/home/img_pqzt_fujin.png" />
        <img src="@/images/home/img_pqzt_zhenren.png" />
        <img src="@/images/home/img_pqzt_paipai.png" />
        <img src="@/images/home/img_pqzt_youhua.png" />
        <img src="@/images/home/img_pqzt_mimi.png" />
        <img src="@/images/home/img_pqzt_putong.png" />
        <img src="@/images/home/img_pqzt_qingbaoju.png" />
        <img src="@/images/home/img_pqzt_moqiyibai.png" />
      </div>
      <el-image
        class="tipsImg"
        :src="require('@/images/home/img_pq_jieshao.png')"
      />
    </div>

    <!-- 次级导航-皮雀功能介绍 -->
    <div class="introduceBox" ref="introduceBox" id="introduce">
      <div class="introduceNav">
        <el-tabs v-model="introduceNav" @tab-click="introduceNavClick">
          <el-tab-pane label="附近/推荐" name="1"></el-tab-pane>
          <el-tab-pane label="真人认证" name="2"></el-tab-pane>
          <el-tab-pane label="拍拍交友" name="3"></el-tab-pane>
          <el-tab-pane label="大林子" name="4"></el-tab-pane>
          <el-tab-pane label="心动右滑" name="5"></el-tab-pane>
          <el-tab-pane label="噗通信号" name="6"></el-tab-pane>
          <el-tab-pane label="雀友情报局" name="7"></el-tab-pane>
          <el-tab-pane label="默契100%" name="8"></el-tab-pane>
          <el-tab-pane label="召唤上线" name="9"></el-tab-pane>
        </el-tabs>
      </div>
      <div>
        <div
          class="introduceItem introduceItem1"
          ref="introduceItem1"
          :style="{
            backgroundImage: `url(${require('@/images/home/img_gongneng_1.jpg')}`,
          }"
        >
          <div class="introduceItemInner">
            <div class="introduceItemText">
              <el-image
                class="introduceItemIcon"
                :src="require('@/images/home/ic_gn_fujin.png')"
              />
              <div class="introduceItemTitle">
                <div class="z2">附近推荐</div>
                <el-image
                  class="introduceItemBgimg"
                  :src="require('@/images/home/img_xiaobiaoti.png')"
                />
              </div>
              <div class="introduceItemContent">
                让缘分触手可及！<br />有趣的人近在咫尺
              </div>
            </div>
          </div>
        </div>
        <div
          class="introduceItem introduceItem2"
          ref="introduceItem2"
          :style="{
            backgroundImage: `url(${require('@/images/home/img_gongneng_2.jpg')}`,
          }"
        >
          <div class="introduceItemInner">
            <div class="introduceItemText">
              <el-image
                class="introduceItemIcon"
                :src="require('@/images/home/ic_gn_zhenrenrenzheng.png')"
              />
              <div class="introduceItemTitle">
                <div class="z2">真人认证</div>
                <el-image
                  class="introduceItemBgimg"
                  :src="require('@/images/home/img_xiaobiaoti.png')"
                />
              </div>
              <div class="introduceItemContent">
                真人+实名认证让你安心交友！<br />脱单第一步从真人多的皮雀开始
              </div>
            </div>
          </div>
        </div>
        <div
          class="introduceItem introduceItem3"
          ref="introduceItem3"
          :style="{
            backgroundImage: `url(${require('@/images/home/img_gongneng_3.jpg')}`,
          }"
        >
          <div class="introduceItemInner">
            <div class="introduceItemText">
              <el-image
                class="introduceItemIcon"
                :src="require('@/images/home/ic_gn_paipaijiaoyou.png')"
              />
              <div class="introduceItemTitle">
                <div class="z2">拍拍交友</div>
                <el-image
                  class="introduceItemBgimg"
                  :src="require('@/images/home/img_xiaobiaoti.png')"
                />
              </div>
              <div class="introduceItemContent">
                用一次就上瘾的识别神器！<br />只需3秒即可找到心仪的TA
              </div>
            </div>
          </div>
        </div>
        <div
          class="introduceItem introduceItem4"
          ref="introduceItem4"
          :style="{
            backgroundImage: `url(${require('@/images/home/img_gongneng_4.jpg')}`,
          }"
        >
          <div class="introduceItemInner">
            <div class="introduceItemText">
              <el-image
                class="introduceItemIcon"
                :src="require('@/images/home/ic_gn_dalinzi.png')"
              />
              <div class="introduceItemTitle">
                <div class="z2">大林子</div>
                <el-image
                  class="introduceItemBgimg"
                  :src="require('@/images/home/img_xiaobiaoti.png')"
                />
              </div>
              <div class="introduceItemContent">
                你我的故事从点赞开始！<br />自由分享生活的每一个瞬间
              </div>
            </div>
          </div>
        </div>
        <div
          class="introduceItem introduceItem5"
          ref="introduceItem5"
          :style="{
            backgroundImage: `url(${require('@/images/home/img_gongneng_5.jpg')}`,
          }"
        >
          <div class="introduceItemInner">
            <div class="introduceItemText">
              <el-image
                class="introduceItemIcon"
                :src="require('@/images/home/ic_gn_xindongyouhua.png')"
              />
              <div class="introduceItemTitle">
                <div class="z2">心动右滑</div>
                <el-image
                  class="introduceItemBgimg"
                  :src="require('@/images/home/img_xiaobiaoti.png')"
                />
              </div>
              <div class="introduceItemContent">
                超多帅哥美女大饱眼福！<br />左滑or右滑，社交主动权在你手中
              </div>
            </div>
          </div>
        </div>
        <div
          class="introduceItem introduceItem6"
          ref="introduceItem6"
          :style="{
            backgroundImage: `url(${require('@/images/home/img_gongneng_6.jpg')}`,
          }"
        >
          <div class="introduceItemInner">
            <div class="introduceItemText">
              <el-image
                class="introduceItemIcon"
                :src="require('@/images/home/ic_gn_putongxinhao.png')"
              />
              <div class="introduceItemTitle">
                <div class="z2">噗通信号</div>
                <el-image
                  class="introduceItemBgimg"
                  :src="require('@/images/home/img_xiaobiaoti.png')"
                />
              </div>
              <div class="introduceItemContent">
                缘分来临就会响起心动的信号！<br />双击头像快速打开附近有缘人的窗口
              </div>
            </div>
          </div>
        </div>
        <div
          class="introduceItem introduceItem7"
          ref="introduceItem7"
          :style="{
            backgroundImage: `url(${require('@/images/home/img_gongneng_7.jpg')}`,
          }"
        >
          <div class="introduceItemInner">
            <div class="introduceItemText">
              <el-image
                class="introduceItemIcon"
                :src="require('@/images/home/ic_gn_qingbaoju.png')"
              />
              <div class="introduceItemTitle">
                <div class="z2">雀友情报局</div>
                <el-image
                  class="introduceItemBgimg"
                  :src="require('@/images/home/img_xiaobiaoti.png')"
                />
              </div>
              <div class="introduceItemContent">
                找人找物一键发布！<br />召集雀友的力量<br />助你实现一切找寻的愿望
              </div>
            </div>
          </div>
        </div>
        <div
          class="introduceItem introduceItem8"
          ref="introduceItem8"
          :style="{
            backgroundImage: `url(${require('@/images/home/img_gongneng_8.jpg')}`,
          }"
        >
          <div class="introduceItemInner">
            <div class="introduceItemText">
              <el-image
                class="introduceItemIcon"
                :src="require('@/images/home/ic_gn_moqiyibai.png')"
              />
              <div class="introduceItemTitle">
                <div class="z2">默契100%</div>
                <el-image
                  class="introduceItemBgimg"
                  :src="require('@/images/home/img_xiaobiaoti.png')"
                />
              </div>
              <div class="introduceItemContent">
                让志趣相投的人更好相遇！<br />默契考验让你跟三观不合的人说拜拜~
              </div>
            </div>
          </div>
        </div>
        <div
          class="introduceItem introduceItem9"
          ref="introduceItem9"
          :style="{
            backgroundImage: `url(${require('@/images/home/img_gongneng_9.jpg')}`,
          }"
        >
          <div class="introduceItemInner">
            <div class="introduceItemText">
              <el-image
                class="introduceItemIcon"
                :src="require('@/images/home/ic_gn_zhaohuanshangxian.png')"
              />
              <div class="introduceItemTitle">
                <div class="z2">召唤上线</div>
                <el-image
                  class="introduceItemBgimg"
                  :src="require('@/images/home/img_xiaobiaoti.png')"
                />
              </div>
              <div class="introduceItemContent">
                想念TA却不在线？<br />召唤好友上限，重启畅聊时刻
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 公司简介（2023.12.14百度过审调整） -->
    <div class="aboutWMBox" ref="Map" id="about">
      <el-image
        class="aboutWMImg"
        :src="require('@/images/home/img_weimijieshao.jpg')"
      />
    </div>
    <div
      class="Map pageItem"
      :style="{
        backgroundImage:
          'url(' + require('@/images/home/img_pqbg_lianxi.jpg') + ')',
      }"
    >
      <div class="mapBox">
        <div class="companyInfo">
          <el-image
            class="companyInfoTitle"
            :src="require('@/images/home/img_lianxi_biaoti.png')"
          />
          <div class="companyCardBox">
            <!-- <div class="companyCard">
              <div class="companyCardTitle">广州微觅科技</div>
              <div class="companyCardHeng"></div>
              <div class="companyCardRow">
                联系邮箱
                <div class="mgl16">pique@myouxun.com</div>
              </div>
              <div class="companyCardRow">
                联系方式
                <div class="mgl16">020-36139859</div>
              </div>
              <div class="companyCardRow">
                公司地址
                <div class="mgl16">广州市白云区鹤龙街黄边北路63号6201</div>
              </div>
            </div> -->
            <div class="companyCard">
              <div class="companyCardTitle">南宁微觅科技</div>
              <div class="companyCardHeng"></div>
              <div class="companyCardRow">
                联系邮箱
                <div class="mgl16">pique@myouxun.com</div>
              </div>
              <div class="companyCardRow">
                联系方式
                <div class="mgl16">020-36139859</div>
              </div>
              <div class="companyCardRow">
                公司地址
                <div class="mgl16">广西壮族自治区南宁江南万达广场C16号楼</div>
              </div>
            </div>
            <div class="companyCard companyCardsmall">
              <el-image
                class="companyCardQrcode"
                :src="require('@/images/home/picQrcode.png')"
              />
              <div class="companyCardQrcodeText">
                更多有趣内容<br />请关注微信公众号<br />「皮雀官方号」
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 地图 -->
    <!-- <div class="Map pageItem" ref="Map"
			:style="{ backgroundImage: 'url(' + require('@/images/home/img_pqbg_lianxi.jpg') + ')' }">
			<div class="mapBox">
				<div class="mapItem">
					<div id="gao-de-map" style="height: 100%;width: 100%;border-radius: 16px 0px 0px 16px;"></div>
				</div>
				<div class="otherMsg">
					<div class="otherMsgT">广州微觅科技有限公司</div>
					<div class="otherMsgH"></div>
					<div class="otherCard">
						<div class="otherCardItem">
							<el-image class="otherCardImg" :src="require('@/images/home/ic_youxiang.png')"/>
							<div class="otherCardName">联系邮箱</div>
							<div class="otherCardName2">pique@myouxun.com</div>
						</div>
						<div class="otherCardItem">
							<el-image class="otherCardImg" :src="require('@/images/home/ic_dianhua.png')"/>
							<div class="otherCardName">联系电话</div>
							<div class="otherCardName2">020-36139859</div>
						</div>
						<div class="otherCardItem">
							<el-image class="otherCardQrcode" :src="require('@/images/home/picQrcode.png')"/>
							<div class="otherCardName">更多有趣内容<br>请关注微信公众号<br>「皮雀官方号」</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
  </div>
</template>

<script>
// import AMapLoader from "@amap/amap-jsapi-loader";
import Header from "@/components/Header.vue";

export default {
  components: {
    Header,
  },
  name: "AppMain",
  props: {
    index: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      currentTab: "1",
      introduceNav: "1",
      map: null,
      showFixedNav: false,
      showQrcodeIos: false,
      showQrcodeAd: false,

      introduceItemCount: 0,
    };
  },
  mounted() {
    this.runPhoneAnim();

    import("@amap/amap-jsapi-loader").then((AMapLoader) => {
      // use code
      // 设置安全密钥
      window._AMapSecurityConfig = {
        securityJsCode: "6933d28ebb86730a96d1f50277633000",
      };
      AMapLoader.load({
        key: "bba0b0bc3ac796da8a4d682489f0cdad", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("gao-de-map", {
            //设置地图容器id
            viewMode: "2D", //是否为3D地图模式
            zoom: 16, //设置地图显示的缩放级别
            center: [108.313547, 22.789868], //初始化地图中心点位置
          });
          var marker = new AMap.Marker({
            position: [108.313547, 22.789868],
            map: this.map,
          });
          marker.setLabel({
            offset: new AMap.Pixel(-5, -10), // 设置文本标注偏移量
            content: "<div>广西南宁江南万达广场C16号</div>", // 设置文本标注内容
            direction: "top", // 设置文本标注方向
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });

    window.addEventListener("scroll", this.handleScroll);

    this.introduceItemCount =
      document.querySelectorAll(".introduceItem").length;
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    runPhoneAnim() {
      const TOP = 90;
      const GAP_TOP = 64;
      const GAP_EDGE = 102;

      const box = this.$refs.phoneBox;
      const imgs = box.querySelectorAll("img");
      const boxRect = box.getBoundingClientRect();
      const boxWidth = boxRect.width;
      const boxHeight = boxRect.height;
      const boxCenterX = boxWidth / 2;
      const boxCenterY = boxHeight / 2;
      const imgWidth = 203;
      const imgHeight = 84;

      const style = document.createElement("style");
      document.head.appendChild(style);
      const sheet = style.sheet;

      const baseSelector = "phone-img";

      function setImgStyle(index, img, top, left, offsetX, offsetY) {
        const originX = offsetX;
        const originY = offsetY;
        const fromX = originX * 0.1;
        const fromY = originY * 0.1;
        const toX = -fromX;
        const toY = -fromY;
        img.style.setProperty("left", left + "px");
        img.style.setProperty("top", top + "px");
        img.style.setProperty(
          "transform",
          `translate(${originX}px, ${originY}px)`
        );
        const selector = `${baseSelector}-${index}`;

        const enterFrom = `from { transform: translate(${originX}px, ${originY}px)}`;
        const enterTo = `to { transform: translate(${fromX}px, ${fromY}px)}`;
        const enterKeyframeRule = `@keyframes ${selector}-enter { ${enterFrom} ${enterTo} }`;

        const from = `from { transform: translate(${fromX}px, ${fromY}px)}`;
        const to = `to { transform: translate(${toX}px, ${toY}px)}`;
        const keyframeRule = `@keyframes ${selector} { ${from} ${to} }`;

        const enterDelay = index * 0.1 + 0.2;
        const enterDuration = 2;
        const delay = enterDelay + enterDuration;
        const duration = 2;
        const enterAnimation = `${selector}-enter ${enterDuration}s ${enterDelay}s`;
        const animation = `${selector} ${duration}s ease-out ${delay}s infinite alternate`;
        const styleRule = `.${selector} { animation: ${enterAnimation}, ${animation} }`;

        sheet.insertRule(enterKeyframeRule);
        sheet.insertRule(keyframeRule);
        sheet.insertRule(styleRule);
        img.classList.add(selector);
      }

      for (let i = 0; i < imgs.length; i++) {
        const img = imgs[i];
        const top =
          TOP + ((i % 2 !== 0 ? i - 1 : i) / 2) * (GAP_TOP + imgHeight);
        const left =
          i % 2 === 0
            ? i % 4 === 0
              ? GAP_EDGE
              : 0
            : boxWidth - imgWidth - ((i - 1) % 4 === 0 ? GAP_EDGE : 0);
        const imgCenterX = left + imgWidth / 2;
        const imgCenterY = top + imgHeight / 2;
        const offsetX = boxCenterX - imgCenterX;
        const offsetY = boxCenterY - imgCenterY;
        setImgStyle(i, img, top, left, offsetX, offsetY);
      }
    },
    mouseenter(type) {
      if (type == 1) {
        this.showQrcodeIos = true;
      } else if (type == 2) {
        this.showQrcodeAd = true;
      }
    },
    mouseleave(type) {
      if (type == 1) {
        this.showQrcodeIos = false;
      } else if (type == 2) {
        this.showQrcodeAd = false;
      }
    },
    handleScroll() {
      if (window.scrollY > 0 && this.showFixedNav == false) {
        this.showFixedNav = true;
      } else if (window.scrollY === 0) {
        this.showFixedNav = false;
      }

      const navHeight = 120 + 10;
      if (this.$refs.Map.getBoundingClientRect().top < navHeight) {
        this.currentTab = "3";
      } else if (
        this.$refs.introduceBox.getBoundingClientRect().top < navHeight
      ) {
        this.currentTab = "2";
      } else {
        this.currentTab = "1";
      }

      const bothNavHeight = 120 + 78 + 10;
      for (let i = this.introduceItemCount; i > 0; i--) {
        let target = this.$refs[`introduceItem${i}`];
        if (target) {
          if (target.getBoundingClientRect().top < bothNavHeight) {
            this.introduceNav = String(i);
            break;
          }
        }
      }
    },
    initMap() {
      AMapLoader.load({
        key: "bba0b0bc3ac796da8a4d682489f0cdad", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("gao-de-map", {
            //设置地图容器id
            viewMode: "2D", //是否为3D地图模式
            zoom: 16, //设置地图显示的缩放级别
            center: [113.279815, 23.224845], //初始化地图中心点位置
          });
          var marker = new AMap.Marker({
            position: [113.279815, 23.224845], // Marker的位置
            map: this.map,
          });
          marker.setLabel({
            offset: new AMap.Pixel(-5, -10), // 设置文本标注偏移量
            content: "<div>广州嘉禾创意园</div>", // 设置文本标注内容
            direction: "top", // 设置文本标注方向
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    introduceNavClick() {
      const target = this.$refs[`introduceItem${this.introduceNav}`];
      window.scrollTo({
        top: window.scrollY + target.getBoundingClientRect().top - 120 - 78,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
.main {
  /* width: 100vw;
	height: 100vh;
	overflow-x: hidden; */
}

.pageItem {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 1800px;

  position: relative;
}

.headerBorder {
  width: 100vw;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 120px;
  left: 0;
}

.downLoad {
  position: absolute;
  top: 348px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  cursor: pointer;
}

.downLoadBtn {
  width: 194px;
  height: 64px;
  margin: 0 12px;
}

.downLoadQrc {
  -webkit-tap-highlight-color: transparent;
  transition: 300ms ease-in-out;
  position: absolute;
  top: 420px;
  left: 50%;
  transform: translateX(-206px);
  width: 194px;
  height: 194px;
  background: #ffffff;
  box-shadow: 0px 10px 30px 0px rgba(33, 159, 162, 0.2);
  border-radius: 8px;
  overflow: hidden;
  z-index: 2;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downLoadQrcR {
  transform: translateX(12px);
}

.downLoadQrcS {
  opacity: 1;
}

.phoneBox {
  position: absolute;
  top: 460px;
  left: 50%;
  transform: translateX(-50%);
  width: 852px;
  height: 916px;
  background-position: center;
  background-size: 100% 100%;
}

.phoneBox img {
  position: absolute;
  width: 203px;
  height: 84px;
}

.tipsImg {
  position: absolute;
  top: 1376px;
  left: 50%;
  transform: translateX(-50%);
  width: 1200px;
  height: 367px;
}

.introduceNav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 120px;
  background-color: #fff;
  z-index: 99;
}

.introduceItem {
  width: 100vw;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center center;
}

.introduceItemInner {
  width: 1200px;
  flex: none;
}

.introduceItem:nth-child(2n-1) .introduceItemText {
  margin-left: 90px;
}

.introduceItem:nth-child(2n) .introduceItemText {
  margin-left: 660px;
}

.introduceItemIcon {
  margin-bottom: 23px;
  width: 40px;
  height: 40px;
}

.introduceItemTitle {
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #03081b;
  line-height: 32px;
  letter-spacing: 1px;
  position: relative;
}

.z2 {
  position: relative;
  z-index: 2;
}

.introduceItemBgimg {
  position: absolute;
  left: 0;
  top: 24px;
  width: 100px;
  height: 16px;
  z-index: 0;
}

.introduceItemContent {
  margin-top: 45px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #03081b;
  line-height: 48px;
}

.Map {
  height: 594px;
  width: 100vw;
  position: relative;
}

.mapBox {
  width: 1200px;
  height: 450px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 16px;
}

.mapItem {
  width: 600px;
  height: 450px;
  background-color: #626a76;
  border-radius: 16px 0px 0px 16px;
}

.otherMsg {
  background-color: #fff;
  width: 600px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0px 16px 16px 0px;
}

.otherMsgT {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #343940;
  line-height: 28px;
}

.otherMsgH {
  width: 24px;
  height: 4px;
  background: var(--primary-color);
  margin-top: 18px;
  margin-bottom: 40px;
}

.otherCard {
  width: 544px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.otherCardItem {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #f4f8fd;
  width: 168px;
  height: 216px;
}

.otherCardImg {
  width: 48px;
  height: 48px;
  margin-top: 48px;
  margin-bottom: 24px;
}

.otherCardQrcode {
  width: 88px;
  height: 88px;
  margin-top: 26px;
  margin-bottom: 16px;
}

.otherCardName {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #343940;
  line-height: 17px;
  margin-bottom: 8px;
}

.otherCardName2 {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: var(--primary-color);
  line-height: 20px;
  cursor: pointer;
}

.headerRowFixed {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 199;
}
</style>

<style>
.main .el-carousel {
  width: 100%;
  height: 100%;
}

.main .el-carousel__container {
  width: 100vw;
  height: 100vh;
}

.introduceNav .el-tabs__header {
  margin: 0;
}

.introduceNav .el-tabs__item {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1f2626;
  line-height: 24px;
  width: 133px;
  height: 64px;
  padding: 0 !important;
  text-align: center;
  line-height: 64px;
  opacity: 0.8;
}

.introduceNav .el-tabs__nav-wrap::after {
  background-color: transparent !important;
}

.introduceNav .is-active {
  opacity: 1;
  color: var(--primary-color) !important;
}

.introduceNav .el-tabs__active-bar {
  height: 4px;
  background-color: var(--primary-color);
}

.introduceNav .el-tabs__item:hover {
  color: var(--primary-color) !important;
}
.aboutWMBox {
  background-color: rgb(242, 250, 250);
  position: relative;
  min-height: 600px;
  width: 100vw;
  height: 600px;
}
.aboutWMImg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1920px;
  height: 600px;
}
.companyInfo {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.companyInfoTitle {
  margin-top: 32px;
  width: 391px;
  height: 80px;
}
.companyCardBox {
  margin-top: 32px;
  display: flex;
  width: 100%;
  justify-content: center;
}
.companyCard {
  width: 460px;
  height: 216px;
  padding: 24px;
  box-sizing: border-box;
  margin: 0 10px;
  border: 1px solid #eef2f2;

  border-radius: 12px;
}
.companyCardsmall {
  width: 168px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.companyCardTitle {
  font-size: 18px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #343940;
  line-height: 25px;
}
.mgl16 {
  margin-left: 16px;
}
.companyCardHeng {
  width: 24px;
  height: 4px;
  background: var(--primary-color);
  margin-top: 12px;
  margin-bottom: 24px;
}
.companyCardRow {
  display: flex;
  margin-top: 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #343940;
  line-height: 20px;
}
.companyCardQrcode {
  width: 72px;
  height: 72px;
}
.companyCardQrcodeText {
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #343940;
  line-height: 20px;
  text-align: center;
  margin-top: 23px;
}
</style>
