<template>
  <div class="background-container">
    <video ref="videoPlayer" class="background-video" muted playsinline webkit-playsinline autoplay loop>
      <source :src="require('@/images/openVd.mp4')" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <div class="content">
      <!-- 这里可以添加其他内容 -->
      <h1 style="color: #000;">11111111122222222</h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoSource: 'your-video-file.mp4', // 替换为你的视频文件路径
    };
  },
  mounted() {
    // 在组件加载时触发视频播放
    this.playVideo();
  },
  methods: {
    playVideo() {
      const videoPlayer = this.$refs.videoPlayer;
      videoPlayer.play();
    },
  },
};
</script>

<style scoped>
.background-container {
  position: relative;
  width: 100%;
  height: 100vh; /* 设置高度，保证视频充满整个屏幕 */
  overflow: hidden;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 确保视频充满容器 */
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  /* 这里可以根据需要添加其他样式 */
}
</style>
