<template>
  <div>
    <!-- <app-header :index="pageindex" @changPage="changPage"></app-header> -->
    <div class="pcBox">
      <app-main :index="pageindex" @changPage="changPage"></app-main>
    </div>
    <div class="mobileBox">
      <mobile-main :index="pageindex"></mobile-main>
    </div>

    <ScrollToTop />

    <Footer />
  </div>
</template>
<script>
// import AppHeader from "./components/AppHeader"
import AppMain from "./components/AppMain";
import MobileMain from "./components/MobileMain";
import Footer from "@/components/Footer.vue";
import ScrollToTop from "@/components/ScrollToTop.vue";

export default {
  components: {
    // AppHeader,
    AppMain,
    MobileMain,
    Footer,
    ScrollToTop,
  },
  data() {
    return {
      pageindex: "1",
    };
  },
  methods: {
    changPage(e) {
      this.pageindex = e;
    },
  },
};
</script>

<style scoped>
/* 头部样式 */
.header {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 11vh;
  /* background-color: #2d3a4b; */
  z-index: 99;
}

.color {
  background-color: #2d3a4b;
}
.pcBox {
  display: block;
}
.mobileBox {
  display: none;
}
@media screen and (max-width: 768px) {
  .pcBox {
    display: none;
  }
  .mobileBox {
    display: block;
  }
}
</style>
